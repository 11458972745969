import React from 'react';
import { UseMultiSchoolSelect } from 'types';
import Carousel from 'components/sections/carousel/Carousel';
import { SelectionCardAlt } from 'components/form-wizards/micro-portal/SelectionCard/SelectionCardAlt';

interface Props {
  state: UseMultiSchoolSelect['state'];
}

export default function MultiSchoolSelectList({
  state,
}: Props): React.JSX.Element {
  // Find if any school is currently expanded
  const foundIndex = Object.values(state).findIndex(
    (school) => school.isExpanded
  );

  let expandedIndex = foundIndex;
  if (foundIndex === -1) {
    // Check if all schools have been either submitted or skipped
    // Only proceed if there are actually schools to check
    const schools = Object.values(state);
    const allSchoolsHandled =
      schools.length > 0 &&
      schools.every((school) => school.isSubmitted || school.isSkipped);
    // Default to first slide (0) only if all schools are handled
    expandedIndex = allSchoolsHandled ? 0 : -1;
  }

  return (
    <div className="multiSchoolSelectList">
      <Carousel
        currentIndex={expandedIndex}
        showNav={false}
        variant="coverFlow"
      >
        {Object.values(state).map((school) => (
          <SelectionCardAlt
            key={school.schoolInfo.id}
            id={school.schoolInfo.id}
          />
        ))}
      </Carousel>
    </div>
  );
}
