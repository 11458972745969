import { useState, useEffect } from 'react';

function useCarousel(slideCount: number, controlledIndex?: number): {
    currentIndex: number;
    gotoSlide: (index: number) => void;
    gotoNextSlide: () => void;
    gotoPrevSlide: () => void;
} {
    const [currentIndex, setCurrentIndex] = useState<number>(controlledIndex || 0);

    // Keep internal state synced with controlled index
    useEffect(() => {
        if (typeof controlledIndex === 'number' && controlledIndex !== currentIndex) {
            setCurrentIndex(controlledIndex);
        }
    }, [controlledIndex]);

    const gotoSlide = (index: number): void => {
        setCurrentIndex(index);
    };

    const gotoNextSlide = (): void => {
        setCurrentIndex((prevIndex: number) => (prevIndex + 1) % slideCount);
    };

    const gotoPrevSlide = (): void => {
        setCurrentIndex((prevIndex: number) => (prevIndex - 1 + slideCount) % slideCount);
    };

    return { currentIndex, gotoSlide, gotoNextSlide, gotoPrevSlide };
}

export default useCarousel;
