/* TODO: remove these eslint-disable rules */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useRef, useState } from 'react';
import cx from 'utils/classnames';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import MultiSchoolSelectContext from 'components/base/inputs/multiSchoolSelect/multiSchoolSelectContext';
import { Terms } from 'components/base/inputs/multiSchoolSelect/Terms';
import ReactSelect from 'components/base/selects/base-select/ReactSelect';
import { UseMultiSchoolSelect } from 'types';
import { recordSchoolView } from 'app-requests/triadms-apis/microPortalImpressionTracking';
import CloseBtn from 'components/base/closeBtn/CloseBtn';
import SchoolLogo from '../SchoolLogo';
import { SelectionCardActions } from './SelectionCardActions';

interface SelectionCardAltProps {
  id: string;
}

export function SelectionCardAlt(
  props: SelectionCardAltProps
): React.JSX.Element {
  const { id } = props;
  const {
    state,
    handleProgramChange,
    handleSkipSchool,
    handleCloseCard,
    handleSelectSchool,
  } = useContext<UseMultiSchoolSelect>(MultiSchoolSelectContext);

  const {
    isExpanded,
    isSkipped,
    isSubmitted,
    isAccepted,
    isShowingDetails,
    selectedProgram,
    schoolInfo: {
      schoolLogo,
      featuredImage,
      label,
      submitText,
      hasTcpaCheckbox,
      terms,
      matchingProgramOptions: options,
      impressionGuid,
    },
  } = state[id];

  const selectionCardRef = useRef<HTMLDivElement>(null);
  const termsRef = useRef<HTMLDivElement>(null);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(hasTcpaCheckbox);
  const [errorMessage, setErrorMessage] = useState('');

  function handleTcpaCheckboxToggle(hasAccepted: boolean): void {
    setIsSubmitDisabled(!hasAccepted);
    setErrorMessage('');
  }

  function _handleCloseCard(): void {
    handleSkipSchool(id);
  }

  function handleClick(): void {
    if (isExpanded) {
      return;
    }
    // Find if any school is currently expanded
    const expandedSchool = Object.values(state).find(
      (school) => school.isExpanded
    );

    if (expandedSchool) {
      handleCloseCard(expandedSchool.schoolInfo.id);
    }

    if (!isExpanded) {
      handleSelectSchool(id);
    }
  }

  const selectionCardClass = cx('selectionCardAlt', {
    'selectionCardAlt--isShowingDetails': isShowingDetails,
    'selectionCardAlt--isSelected': !isSkipped,
    'selectionCardAlt--isSubmitted': isSubmitted,
    'selectionCardAlt--isAccepted': isAccepted,
    'selectionCardAlt--isExpanded': isExpanded,
  });

  useEffect(() => {
    if (impressionGuid && isExpanded) {
      recordSchoolView([impressionGuid], 'MicroportalSchoolCards');
    }
  }, [impressionGuid, isExpanded]);

  return (
    <div
      className={selectionCardClass}
      ref={selectionCardRef}
      data-tf-element-role="offer"
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleClick();
        }
      }}
    >
      <span className="selectionCardAlt__closeBtn">
        <CloseBtn onClick={_handleCloseCard} />
      </span>

      <div className="selectionCardAlt__featuredImage">
        <img src={featuredImage.mobile} alt="" loading="lazy" />
      </div>
      <div className="selectionCardAlt__body">
        <div className="selectionCardAlt__meta">
          <div className="selectionCardAlt__logo">
            <SchoolLogo
              logo={schoolLogo}
              className="selectionCardAlt__logoImg"
              alt={`${label} logo`}
            />
          </div>
          <h4 className="selectionCardAlt__title">
            <Wysiwyg content={label} isClean />
          </h4>
        </div>

        <div className="selectionCardAlt__content">
          <div className="selectionCardAlt__formCtrl">
            <div className="selectionCardAlt__formLabel">
              <label htmlFor={`selectCardSelection-${id}`}>
                Best Matching Program
              </label>
            </div>
            <div className="selectionCardAlt__formField">
              <ReactSelect
                options={options}
                value={selectedProgram}
                onChange={(program) => handleProgramChange(id, program)}
                name={`selectCardSelection-${id}`}
              />
            </div>
          </div>
          <div
            className={
              errorMessage
                ? 'selectionCardAlt__terms selectionCardAlt__terms--hasError'
                : 'selectionCardAlt__terms'
            }
            ref={termsRef}
          >
            <Terms
              terms={terms}
              schoolName={label}
              schoolImpressionGuid={impressionGuid}
              isExpanded={isExpanded}
              onTcpaCheckboxToggle={handleTcpaCheckboxToggle}
              hasTcpaCheckbox={hasTcpaCheckbox}
            />
          </div>
          {errorMessage && (
            <span className="selectionCardAlt__errorMessage">
              {errorMessage}
            </span>
          )}
        </div>
      </div>
      <div className="selectionCardAlt__actions">
        <SelectionCardActions
          id={id}
          showSelect={false}
          showSubmit
          showCheckbox={false}
          submitText={submitText}
          isSubmitDisabled={isSubmitDisabled}
          onError={() =>
            setErrorMessage(
              'Please accept the terms by checking the checkbox above'
            )
          }
        />
      </div>
    </div>
  );
}
