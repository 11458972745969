import { TRIAD_PROXY_ROUTE } from 'app-requests/apiConstants';
import {
  APIMetaDataInfo,
  LeadSubmitBatchId,
  MicroPortalLeadSubmitResults,
  QuestionRepliesStructureForBackend,
  SchoolImpressionGuid,
} from 'types';
import { getDOMFieldValue } from 'utils/formValuesUtils';
import { LogError, LogInfo } from 'utils/logging';
import { retryablePromise } from 'utils/generalUtils';
import { getPollingHelper } from 'utils/requestWithTransaction';
import request from 'utils/request';
import { trackMicroPortalCardSubmitted } from 'utils/trackingFunctions';
import { getTidCookieOrQueryParams } from 'utils/analyticsHelpers';

const schoolImpressionGuidsSubmitted = new Set();
const batchIdsConversionsTracked = new Set();

/**
 * Submits a single microportal lead
 */
export async function submitSingleMicroPortalLead(
  batchId: LeadSubmitBatchId,
  schoolImpressionGuid: SchoolImpressionGuid | null,
  questionReplies: QuestionRepliesStructureForBackend,
  metaData: APIMetaDataInfo,
  selectedProgramGuid: string | null
): Promise<{ success: boolean }> {
  if (!batchId) {
    LogError('submitSingleMicroPortalLead: No Batch Id');
  }

  if (!schoolImpressionGuid) {
    LogError('submitSingleMicroPortalLead: No School Impression Guid');
  }

  if (!metaData.disclaimerText) {
    LogError('submitSingleMicroPortalLead: No Disclaimer Text');
  }

  if (schoolImpressionGuidsSubmitted.has(schoolImpressionGuid)) {
    LogInfo('MicroPortalLeadSubmit', { description: 'Start Duplicate' });
  } else {
    LogInfo('MicroPortalLeadSubmit', { description: 'Start' });
  }

  const tid = getTidCookieOrQueryParams();

  if (tid) {
    LogInfo('User with transaction id', {
      description: `tid: ${tid} starting submission`,
    });
  }

  trackMicroPortalCardSubmitted();

  const submitResults = await retryablePromise(() =>
    request({
      method: 'post',
      url: `${TRIAD_PROXY_ROUTE}/microportal/MPLeadSubmit`,
      body: {
        questionReplies,
        batchid: batchId,
        submissionImpressionGuid: schoolImpressionGuid,
        programGuid: selectedProgramGuid,
        schoolCode: metaData.schoolCode,
        templateName: metaData.variant,
        tcpaText: metaData.disclaimerText,
        trustedFormUrl: getDOMFieldValue('xxTrustedFormCertUrl_0'),
        leadId: getDOMFieldValue('leadid_token'),
      },
    })
  ).catch(() => ({
    status: 'failed',
  }));

  const isSuccessful = submitResults.status === 'success';

  LogInfo('MicroPortalLeadSubmit', {
    description: isSuccessful ? 'Success' : 'Failed',
  });

  if (tid) {
    LogInfo('User with transaction id', {
      description: `tid: ${tid} submission ${
        isSuccessful ? 'success' : 'failed'
      }`,
    });
  }

  if (isSuccessful) {
    schoolImpressionGuidsSubmitted.add(schoolImpressionGuid);
  }

  if (!isSuccessful) {
    LogError('MicroPortalLeadSubmit: Submitting Lead Failed');
  }

  return {
    success: Boolean(isSuccessful),
  };
}

export async function getMicroPortalLeadSubmitResults({
  transactionId,
}: {
  transactionId: LeadSubmitBatchId;
}): Promise<MicroPortalLeadSubmitResults> {
  const { schoolLeadResponses, summary } = await request({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}/LeadSubmitPolling`,
    body: {
      batchId: transactionId,
    },
  });

  // Note we do not need to check for test mode because backend will set IsFirePixel to false
  const shouldTrackConversion =
    summary.isFirePixel && !batchIdsConversionsTracked.has(transactionId);

  if (shouldTrackConversion) {
    batchIdsConversionsTracked.add(transactionId);
  }

  return {
    shouldTrackConversion,
    profileGuid: summary.profileId,
    summary: {
      revenue: summary.totalLeadRev,
      adjustedRevenue: summary.totalAdjLeadRev,
    },
    isWaitMoreResults: schoolLeadResponses.some(
      ({ leadStatus }: { leadStatus: string }) => {
        return leadStatus === 'UNKNOWN';
      }
    ),
    leadsSubmittedFor: schoolLeadResponses.map(
      ({ leadStatus }: { leadStatus: string }) => ({
        status: leadStatus,
      })
    ),
  };
}

/**
 * Starts polling for a given batch id. If polling is already active for the given
 * batch id, it will not start again. Returns true if polling was started, false
 * otherwise.
 *
 * It will log an error if the polling fails.
 */
let activePollCount = 0;
export function startLeadSubmitPolling(
  batchId: LeadSubmitBatchId,
  numberOfResultsExpected: number
): Promise<MicroPortalLeadSubmitResults> {
  activePollCount += 1;
  const thisPollCount = activePollCount;

  function shouldContinueToPoll(
    results: MicroPortalLeadSubmitResults
  ): boolean {
    // Another polling was started
    if (activePollCount > thisPollCount) {
      return false;
    }

    // we still need more results and/or the conversion was not tracked
    if (!results.shouldTrackConversion && results.isWaitMoreResults) {
      return true;
    }

    // it could be the backend has not added the result to the queue yet, race condition
    if (results.leadsSubmittedFor.length < numberOfResultsExpected) {
      return true;
    }

    return false;
  }

  const { startPolling } = getPollingHelper({
    transactionId: batchId,
    shouldPollFunc: shouldContinueToPoll,
    resultsRequest: getMicroPortalLeadSubmitResults,
  });

  return startPolling().then((results: MicroPortalLeadSubmitResults) => {
    return results;
  });
}
