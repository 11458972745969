import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'utils/classnames';
import { isDynamicDisclaimerStep } from 'components/form-wizards/basic-form/utils';
import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';

interface LoadingButtonProps {
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  text: string;
  loading?: boolean;
}

function LoadingButton(props: LoadingButtonProps): React.ReactElement {
  const { onClick, className, disabled, text, loading } = props;

  const { currentQuestions, isLastStep } = useContext(BasicFormWizardContext);

  const buttonClassName = cx({
    loadingButton: true,
    loadingButton__isDisabled: disabled,
    loadingButton__isLoading: loading,
    [`${className}`]: !!className,
    [`${className}__isDisabled`]: disabled,
    [`${className}__isLoading`]: loading,
  });

  const isASubmitStep = isLastStep || isDynamicDisclaimerStep(currentQuestions);

  return (
    <button
      type="button"
      onClick={onClick}
      className={buttonClassName}
      disabled={disabled}
      data-testid="loadingButton"
      data-tf-element-role={isASubmitStep ? 'submit' : undefined}
    >
      <div className="loadingButton__inner">
        <span className="loadingButton__text">{text}</span>
        {loading && (
          <span className="loadingButton__loader">
            <span className="loadingButton__loadingText">Loading...</span>
            <span className="loadingButton__loadingIcon" />
          </span>
        )}
      </div>
    </button>
  );
}

LoadingButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

export default LoadingButton;
