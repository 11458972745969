/**
 * @summary this file as all the component mappings that Wordpress will be able to render
 */
import { BREAKPOINTS } from 'consts';
import { FormFieldProps } from 'types';
import isEmpty from 'lodash/isEmpty';
import { LogError } from 'utils/logging';
import { FIELD_TYPES } from 'components/AVAILABLE_COMPONENTS';
import Disclaimer from 'components/base/inputs/disclaimer/Disclaimer';
import GroupedButtonSelect from 'components/base/selects/groupedButtonSelect/GroupedButtonSelect';
import Input from 'components/base/inputs/base-input/Input';
import EmailInput from 'components/base/inputs/emailInput/EmailInput';
import MultiSelect from 'components/base/multiSelect/MultiSelect';
import NumberInput from 'components/base/inputs/numberInput/NumberInput';
import PhoneInput from 'components/base/inputs/phoneInput/PhoneInput';
import RadioGroup from 'components/base/radioGroup/RadioGroup';
import RadioGroupBoolean from 'components/base/radioGroup/RadioGroupBoolean';
import RadioGroupButtons from 'components/base/radioGroup/RadioGroupButtons';
import ReactSelect from 'components/base/selects/base-select/ReactSelect';
import ZipCodeInput from 'components/base/inputs/zipCodeInput/ZipCodeInput';
import { DynamicDisclaimer } from './base/inputs/dynamicDisclaimer/DynamicDisclaimer';
import AdditionalSchoolsDisclaimer from './base/inputs/additionalSchoolsDisclaimer/AdditionalSchoolsDisclaimer';
import { SelectionCard } from './form-wizards/micro-portal/SelectionCard/SelectionCard';
import { MultiSchoolSelect } from './base/inputs/multiSchoolSelect/MultiSchoolSelect';
import { GoogleAddressLookup } from './base/inputs/googleAddressLookup/GoogleAddressLookup';

const COMPONENT_FIELD_TYPE_MAP = {
  [FIELD_TYPES.ADDITIONAL_SCHOOLS_DISCLAIMER]: AdditionalSchoolsDisclaimer,
  [FIELD_TYPES.INPUT]: Input,
  [FIELD_TYPES.EMAIL]: EmailInput,
  [FIELD_TYPES.NUMBER]: NumberInput,
  [FIELD_TYPES.PHONE]: PhoneInput,
  [FIELD_TYPES.SELECT]: ReactSelect,
  [FIELD_TYPES.MULTISELECT]: MultiSelect,
  [FIELD_TYPES.RADIO]: RadioGroup,
  [FIELD_TYPES.RADIO_BUTTONS]: RadioGroupButtons,
  [FIELD_TYPES.BOOLEAN]: RadioGroupBoolean,
  [FIELD_TYPES.GROUPED_BUTTON_SELECT]: GroupedButtonSelect,
  [FIELD_TYPES.DISCLAIMER]: Disclaimer,
  [FIELD_TYPES.DYNAMIC_DISCLAIMER]: DynamicDisclaimer,
  [FIELD_TYPES.MULTI_SCHOOL_SELECT]: MultiSchoolSelect,
  [FIELD_TYPES.SELECTION_CARD]: SelectionCard,
  [FIELD_TYPES.ZIPCODE]: ZipCodeInput,
  [FIELD_TYPES.GOOGLE_ADDRESS_LOOKUP]: GoogleAddressLookup,
};

function getField(
  defaultType: FIELD_TYPES,
  controlOverrides: Record<BREAKPOINTS, FIELD_TYPES>,
  currentBreakpoint: BREAKPOINTS
): React.ComponentType<FormFieldProps> {
  let key = defaultType;

  if (!isEmpty(controlOverrides) && currentBreakpoint) {
    key = controlOverrides[currentBreakpoint] || key;
  }

  if (!COMPONENT_FIELD_TYPE_MAP[key]) {
    LogError(`Field Component Not Found: ${key} is not a valid Field`);
    throw new Error(`Field Component Not Found: ${key} is not a valid Field`);
  }

  // using as unknown as FormFieldProps because we should refactor all Field Component to use FormFieldProps
  return COMPONENT_FIELD_TYPE_MAP[
    key
  ] as unknown as React.ComponentType<FormFieldProps>;
}

export default getField;
