/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
// TODO [T1-11709]: Write Unit Tests for Micro Portal functionality
import React, { useEffect, useState } from 'react';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import isBrowser from 'utils/isBrowser';
import { LogError } from 'utils/logging';
import {
  checkboxClick,
  schoolTCPAView,
} from 'app-requests/triadms-apis/microPortalImpressionTracking';
import { SchoolImpressionGuid } from 'types';

interface TermsProps {
  terms: string;
  schoolName: string;
  onTcpaCheckboxToggle: (isAccepted: boolean) => void;
  hasTcpaCheckbox: boolean;
  schoolImpressionGuid: SchoolImpressionGuid;
  isExpanded: boolean;
}

let TCP_COUNTER = 1;
const ELEMENTS_ID: Record<string, number> = {};

function getElementId(schoolName: string): number {
  if (!isBrowser()) {
    LogError('Do not run this on server');
    return 0;
  }

  if (!ELEMENTS_ID[schoolName]) {
    ELEMENTS_ID[schoolName] = TCP_COUNTER;
    TCP_COUNTER += 1;
  }

  return ELEMENTS_ID[schoolName];
}

function getTCPElementId(schoolName: string): string {
  return `leadid_tcpa_disclosure_${getElementId(schoolName)}`;
}

export function Terms(props: TermsProps): React.JSX.Element {
  const { terms, schoolName, schoolImpressionGuid, isExpanded } = props;
  const [isAccepted, setIsAccepted] = useState(false);

  function handleCheckboxChange(
    e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLLabelElement>
  ): void {
    if (!props.hasTcpaCheckbox) return;
    e.preventDefault();
    const newValue = !isAccepted;
    setIsAccepted(newValue);
    props.onTcpaCheckboxToggle(newValue);
    checkboxClick(schoolImpressionGuid, terms, newValue);
  }

  useEffect(() => {
    if (isExpanded) {
      schoolTCPAView(schoolImpressionGuid, terms);
    }
  }, [isExpanded]);

  return (
    <div className="terms">
      {props.hasTcpaCheckbox && (
        <>
          <input
            checked={isAccepted}
            className="terms__checkbox"
            id={getTCPElementId(schoolName)}
            onChange={handleCheckboxChange}
            type="checkbox"
            data-testid="disclaimer"
            data-tf-element-role="consent-opt-in"
          />
          <label
            className="terms__checkboxLabel"
            onClick={handleCheckboxChange}
          >
            <span className="terms__checkboxLabelText">I accept the terms</span>
          </label>
        </>
      )}

      <label
        data-tf-element-role="consent-language"
        htmlFor={getTCPElementId(schoolName)}
        className="terms__label"
        onClick={handleCheckboxChange}
        style={{ cursor: props.hasTcpaCheckbox ? 'pointer' : 'default' }}
      >
        <Wysiwyg content={terms} />
      </label>

      {!props.hasTcpaCheckbox && (
        <input
          type="hidden"
          id={getTCPElementId(schoolName)}
          name={getTCPElementId(schoolName)}
        />
      )}
    </div>
  );
}
