import { LogWarning } from 'utils/logging';
import request from 'utils/request';
import {
    TRIAD_PROXY_ROUTE,
} from 'app-requests/apiConstants';

interface GetApplicationLinksParams {
    sessionId: string;
    schoolCode: string;
    pageViewId: string;
    leadGuid: string;
}

export async function getApplicationLinks({
    sessionId,
    schoolCode,
    pageViewId,
    leadGuid,
}: GetApplicationLinksParams): Promise<{ applicationLink: string }> {
    try {
        const response = await request({
            method: 'post',
            url: `${TRIAD_PROXY_ROUTE}/thankyoulinks`,
            body: {
                sessionId,
                schoolCode,
                pageViewId,
                leadGuid,
            },
        });

        return {
            applicationLink: response?.Links?.ApplicationLink,
        };
    } catch (error) {
        LogWarning('Error fetching application links', {
            description: (error as unknown as Error).message,
        });
        return { applicationLink: '' };
    }


}
