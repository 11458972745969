import {
  BasicFormWizardContext,
  FormValues,
  LeadSubmitResponse,
  MicroPortalLeadSubmitResults,
} from 'types';
import {
  FLOODLIGHT_EVENTS,
  trackFloodlightActivity,
  trackGAConversion,
} from './trackingFunctions';
import { LogError, LogInfo } from './logging';
import { getTidCookieOrQueryParams } from './analyticsHelpers';

/**
 * @summary This function is used to track the conversion of a form submission.
 */
export function handleConversionTracking(
  formSubmitResponse: MicroPortalLeadSubmitResults | LeadSubmitResponse,
  formConfigs: BasicFormWizardContext['formConfigs'],
  metaData: {
    floodlightActivityValues: object;
    floodLightActivityFilters: object;
    formValues: FormValues;
  }
): MicroPortalLeadSubmitResults | LeadSubmitResponse {
  const { shouldTrackConversion, leadsSubmittedFor } = formSubmitResponse;

  const tid = getTidCookieOrQueryParams();

  if (tid) {
    LogInfo('User with transaction id', {
      description: `tid: ${tid} shouldTrackConversion: ${shouldTrackConversion}`,
    });
  }

  if (!shouldTrackConversion) {
    return formSubmitResponse;
  }

  try {
    const [submittedUser] = leadsSubmittedFor || [];
    let revenue;
    let adjustedRevenue;

    if ('summary' in formSubmitResponse) {
      revenue = formSubmitResponse.summary.revenue;
      adjustedRevenue = formSubmitResponse.summary.adjustedRevenue;
    } else {
      revenue = submittedUser?.revenue;
      adjustedRevenue = submittedUser?.adjustedRevenue;
    }

    const floodlightValues = metaData?.floodlightActivityValues; // should always be present, from server
    const userFilterValues = metaData?.floodLightActivityFilters; // getUserSession, from client side

    trackGAConversion(revenue, adjustedRevenue, formSubmitResponse.profileGuid);

    if (floodlightValues && userFilterValues) {
      trackFloodlightActivity(
        FLOODLIGHT_EVENTS.PROFILE_PAYABLE_USER,
        floodlightValues,
        userFilterValues,
        {
          revenue,
          adjustedRevenue,
          schoolCode: formConfigs.schoolCode,
          formValues: metaData.formValues,
        }
      );
    } else {
      // Do we want to know if this was not tracked?
    }
  } catch (error: unknown) {
    LogError(
      `Failed to track Floodlight Activity: ${(error as Error).message}`
    );
  }

  return formSubmitResponse;
}
