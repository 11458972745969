import React, { useContext, useEffect, useState } from 'react';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import cx from 'utils/classnames';
import { FIELD_NAMES, QUESTION_ID_TO_CONTACT_TYPE_MAP } from 'consts';
import getField from 'components/FIELD_TYPE_MAP';
import { QuestionPropType } from 'utils/propTypes';
import {
  FIELD_TYPES,
  enterSubmitEnabledFields,
  optionEnabledFields,
} from 'components/AVAILABLE_COMPONENTS';
import useTokenReplacement from 'hooks/custom/useTokenReplacement';
import validateStep from 'hooks/custom/forms/basic-wizard/utils/validateStep';
import DefaultErrorBoundary from 'components/error-boundaries/DefaultErrorBoundary';
import ToolTip from 'components/base/toolTip/ToolTip';
import HelpText from 'components/base/helpText/HelpText';
import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';
import GlobalContext from 'hooks/contexts/GlobalContext';
import { trackQuestionViewed } from 'utils/trackingFunctions';
import { STATIC_PROPS } from './triadToPropsTransform';

const { DISCLAIMER, DYNAMIC_DISCLAIMER } = FIELD_TYPES;

export default function FormField({ question = {}, fieldRef }) {
  const { formValues, formStatus, handleChange, handleSubmit, setFieldError } =
    useContext(BasicFormWizardContext);

  const {
    windowSize: { currentBreakpoint = '' },
  } = useContext(GlobalContext);
  const [isFocused, setIsFocused] = useState(false);

  const _onChange = (value, event) => {
    handleChange(value, event);
  };

  const _handleFocus = (focusState) => {
    setIsFocused(focusState);
  };

  const { isSubmitting, errors, dynamicOptions } = formStatus;
  const {
    id,
    label,
    name,
    type,
    required,
    options,
    controlOverrides = {},
    additionalProperties = {},
    showCityState,
    meta,
    isPii,
  } = question;

  const tokenizedLabel = useTokenReplacement(label);

  const Field = getField(type, controlOverrides, currentBreakpoint);

  const shouldHaveOptions = optionEnabledFields.includes(type);

  const shouldSubmitOnEnter = enterSubmitEnabledFields.includes(type);

  const _onBlur = () => {
    if (errors[name]) {
      validateStep([question], formValues)
        .then(() => {
          setFieldError(name, '');
        })
        .catch((newErrors) => {
          /* no need to set the error as this will only run if the error message is already set */
          if (errors[name] !== newErrors[name]) {
            setFieldError(name, newErrors[name]);
          }
        });
    }

    _handleFocus(false);
  };

  const fieldProps = {
    name,
    required,
    value: formValues[name],
    disabled: isSubmitting,
    isFocused,
    showCityState,
    animatedLabel: get(
      additionalProperties[currentBreakpoint],
      'animatedLabel.text',
      ''
    ),
    placeholder: get(
      additionalProperties[currentBreakpoint],
      'placeHolderText.text',
      ''
    ),
    onChange: _onChange,
    onFocus: () => _handleFocus(true),
    onBlur: _onBlur,
    meta,
    label,
    error: errors[name],
    isPii,
  };

  const helpText = get(additionalProperties[currentBreakpoint], 'helpText', {});
  const toolTip = get(additionalProperties[currentBreakpoint], 'toolTip', {});
  const width = get(
    additionalProperties[currentBreakpoint],
    ['width', 'width'],
    'medium'
  );

  const hiddenLabel = get(
    additionalProperties[currentBreakpoint],
    'hiddenLabel',
    false
  );

  if (shouldHaveOptions) {
    fieldProps.options = get(dynamicOptions, `[${id}].options`) || options;
    fieldProps.hasDefault = false;
  }

  if (shouldSubmitOnEnter) {
    fieldProps.onEnter = handleSubmit;
  }

  // some values are not coming from backend, this gives us a hook to hijack a questions props
  const staticProps = get(STATIC_PROPS, id, () => ({}))(fieldProps);

  Object.assign(fieldProps, staticProps);

  useEffect(() => {
    const trackTimeout = setTimeout(() => {
      trackQuestionViewed(question, formValues);
    }, 500);

    return () => clearTimeout(trackTimeout);
  }, [question.label]);

  const componentType = controlOverrides[currentBreakpoint]
    ? controlOverrides[currentBreakpoint]
    : type;

  const shouldShowLabel = ![DISCLAIMER, DYNAMIC_DISCLAIMER].includes(
    componentType
  );

  const formFieldClass = cx({
    formField: true,
    'formField--isRequired': required,
    'formField--hasError': !!get(formStatus, ['errors', name]),
    'formField--isFocused': isFocused,
    'formField--isDisabled':
      (shouldHaveOptions && !get(fieldProps, 'options.length')) ||
      fieldProps.disabled,
    'formField--hasHiddenLabel': hiddenLabel,
    'formField--hasHelpText': !isEmpty(helpText),
    'formField--hasValue': !!fieldProps.value,
    [`formField--${camelCase(name)}`]: !!name,
    [`formField--${camelCase(width)}Width`]: !!width,
    [`formField--${camelCase(componentType)}Component`]: !!componentType,
  });

  return (
    <div className={formFieldClass}>
      {shouldShowLabel && (
        <label
          className="formField__label"
          htmlFor={name}
          data-test="field-label"
        >
          <span dangerouslySetInnerHTML={{ __html: tokenizedLabel }} />
          {!isEmpty(toolTip) && (
            <span className="formField__toolTip">
              <ToolTip
                label={toolTip.label}
                positioning={toolTip?.positioning?.[currentBreakpoint]}
              >
                {toolTip.text}
              </ToolTip>
            </span>
          )}
        </label>
      )}
      <DefaultErrorBoundary meta={{ questionId: id, questionName: name }}>
        <div
          className="formField__question"
          data-test="field-container"
          data-tf-element-role={QUESTION_ID_TO_CONTACT_TYPE_MAP[question.id]}
        >
          <Field fieldRef={fieldRef} {...fieldProps} />
        </div>
        {!isEmpty(helpText) && (
          <HelpText
            text={helpText.text}
            isVisible={helpText.isVisible}
            fieldIsFocused={isFocused}
          />
        )}
        {/* // TODO we should have a flag that indicates these special fields that have many values */}
        {errors[name] && name !== FIELD_NAMES.PRIMARY_PHONE && (
          <div className="formField__error">{errors[name]}</div>
        )}
      </DefaultErrorBoundary>
    </div>
  );
}

FormField.propTypes = {
  fieldRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  question: QuestionPropType,
};
