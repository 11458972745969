import {
  applyPreFills,
  setValueIfOnlyOneOption,
  getValidAndVisibleQuestions,
} from 'components/form-wizards/basic-form/utils';
import moveToNextStep from './moveToNextStep';

export default function setDynamicOptions(state, action) {
  const updatedState = {
    ...state,
    formStatus: {
      ...state.formStatus,
      dynamicOptions: {
        ...state.formStatus.dynamicOptions,
        ...action.dynamicOptions,
      },
    },
  };

  // handle options that were loaded that have a preFill attribute
  updatedState.formValues = applyPreFills(
    updatedState,
    state.formValues
  ).updatedForm;

  if (!state.formConfigs.disableAutoSelect) {
    // handle when only 1 option is loaded
    updatedState.formValues = setValueIfOnlyOneOption(
      updatedState.formValues,
      updatedState.formStatus.dynamicOptions,
      state.allQuestionsInForm
    );
  }

  // if there are no valid and visible questions, move to the next step
  if (
    getValidAndVisibleQuestions(
      updatedState.formConfigs.steps[updatedState.currentStepIndex],
      updatedState
    ).length === 0
  ) {
    return moveToNextStep(updatedState);
  }

  return updatedState;
}
