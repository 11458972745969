import React from 'react';
import classNames from 'utils/classnames';
import { capitalizeFirstLetter } from 'utils/stringHelpers';

interface WysiwygProps {
  content?: string;
  textAlignment?: string;
  isReversed?: string | boolean;
  isClean?: boolean;
}

function Wysiwyg({
  content,
  textAlignment,
  isReversed,
  isClean = false,
}: WysiwygProps): React.JSX.Element {
  const baseClassName = isClean ? 'wysiwygClean' : 'wysiwyg';
  const wysiwygClassName = classNames({
    [baseClassName]: true,
    [`${baseClassName}--text${
      textAlignment ? capitalizeFirstLetter(textAlignment) : ''
    }`]: !!textAlignment,
    [`${baseClassName}--isReversed`]: isReversed,
  });

  return (
    <div
      className={wysiwygClassName}
      dangerouslySetInnerHTML={{ __html: content || '' }}
    />
  );
}

export default Wysiwyg;
