import CarouselDirectionNav from 'components/sections/carousel/CarouselDirectionNav';
import CarouselIndexNav from 'components/sections/carousel/CarouselIndexNav';
import useCarousel from 'components/sections/carousel/useCarousel';
import React, { Children, useRef, useEffect, useState } from 'react';
import { scrollTo } from 'utils/generalUtils';
import cx from 'utils/classnames';
import CarouselSlide from './CarouselSlide';

interface CarouselProps {
  children: React.ReactNode;
  onSlideAnimationStart?: (index: number) => void;
  onSlideAnimationComplete?: (index: number) => void;
  currentIndex?: number;
  showNav?: boolean;
  variant?: 'coverFlow' | 'default';
}

function Carousel({
  children,
  onSlideAnimationStart = (): void => {},
  onSlideAnimationComplete = (): void => {},
  currentIndex: controlledIndex,
  showNav = true,
  variant = 'default',
}: CarouselProps): React.ReactElement {
  const slides = Children.toArray(children).filter(React.isValidElement);
  const { currentIndex, gotoSlide, gotoNextSlide, gotoPrevSlide } = useCarousel(
    slides.length,
    controlledIndex
  );

  const carouselRef = useRef<HTMLDivElement>(null);
  const slidesListRef = useRef<HTMLOListElement>(null);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [transitionState, setTransitionState] = useState({
    isTransitioning: false,
    type: null as 'start' | 'end' | null,
  });

  useEffect(() => {
    const slidesList = slidesListRef.current;
    if (!slidesList) return;

    const handleTransitionStart = (e: TransitionEvent): void => {
      if (e.propertyName === 'transform' && e.target === slidesList) {
        setTransitionState({ isTransitioning: true, type: 'start' });
        onSlideAnimationStart(currentIndex);
      }
    };

    const handleTransitionEnd = (e: TransitionEvent): void => {
      if (e.propertyName === 'transform' && e.target === slidesList) {
        setTransitionState({ isTransitioning: false, type: 'end' });
        onSlideAnimationComplete(currentIndex);
      }
    };

    slidesList.addEventListener('transitionstart', handleTransitionStart);
    slidesList.addEventListener('transitionend', handleTransitionEnd);

    // eslint-disable-next-line consistent-return
    return () => {
      slidesList.removeEventListener('transitionstart', handleTransitionStart);
      slidesList.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, [currentIndex, onSlideAnimationStart, onSlideAnimationComplete]);

  // set the carousel width
  useEffect(() => {
    if (carouselRef.current) {
      setCarouselWidth(carouselRef.current.offsetWidth);
    }
  }, [carouselRef]);

  // scroll to the active slide when the index changes
  useEffect(() => {
    if (carouselRef?.current && currentIndex > 0) {
      const offset = 25; // adjust this value as needed
      const elementPosition = carouselRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - offset;

      scrollTo(offsetPosition);
    }
  }, [currentIndex]);

  function getSlidePosition(index: number): 'prev' | 'next' | 'inactive' {
    if (index === currentIndex - 1) {
      return 'prev';
    }

    if (index === currentIndex + 1) {
      return 'next';
    }
    return 'inactive';
  }

  return (
    <div
      data-testid="carousel"
      className={cx('carousel', {
        'carousel--isTransitioning': transitionState.isTransitioning,
        'carousel--transitionStart': transitionState.type === 'start',
        'carousel--transitionEnd': transitionState.type === 'end',
        [`carousel--${variant}`]: variant,
      })}
      style={
        {
          '--carouselSlideCount': slides.length,
          '--carouselWidth': `${carouselWidth}`,
          '--activeSlideIndex': currentIndex,
        } as React.CSSProperties
      }
    >
      <div className="carousel__inner">
        <div className="carousel__slides" ref={carouselRef}>
          <ol className="carousel__slidesList" ref={slidesListRef}>
            {Children.map(slides, (child, index) => {
              const position = getSlidePosition(index);
              let relativePath: 'beforeActive' | 'afterActive' | undefined;
              const relativeIndex = index - currentIndex;

              if (index !== currentIndex) {
                if (index < currentIndex) {
                  relativePath = 'beforeActive';
                } else if (index > currentIndex) {
                  relativePath = 'afterActive';
                }
              }

              return (
                <CarouselSlide
                  isActive={index === currentIndex}
                  position={position}
                  relativePath={relativePath}
                  relativeIndex={relativeIndex}
                >
                  {child}
                </CarouselSlide>
              );
            })}
          </ol>
        </div>
        {slides.length > 1 && showNav && (
          <div className="carousel__nav">
            <CarouselIndexNav
              currentIndex={currentIndex}
              slides={slides as React.ReactElement[]}
              handleGotoSlide={(index) => {
                gotoSlide(index);
              }}
            />
            <CarouselDirectionNav
              handleGotoNext={() => {
                gotoNextSlide();
              }}
              handleGotoPrev={() => {
                gotoPrevSlide();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Carousel;
