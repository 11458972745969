import { useContext, useMemo } from 'react';
import GlobalContext from 'hooks/contexts/GlobalContext';
import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';
import { QUESTION_IDS, FIELD_NAMES } from 'consts';
import { findValueInOptions } from 'utils/formValuesUtils';
import { LogDebug } from 'utils/logging';

const { SUBJECT_0F_INTEREST, FIRST_NAME, LAST_NAME, PRIMARY_PHONE } =
  QUESTION_IDS;

const { SECONDARY_PHONE } = FIELD_NAMES;
export function replaceTokens(text, tokenMap = {}) {
  // filter out any tokens that are improperly formatted
  // and create a single string to regex against
  const tokenStringsForRegExt = Object.keys(tokenMap)
    .filter((token) => token.match(/^{\w+}$/gi))
    .join('|');
  if (!tokenStringsForRegExt) {
    return text;
  }

  const tokensRegEx = new RegExp(tokenStringsForRegExt, 'gi');

  return text.replace(tokensRegEx, (match) => {
    return tokenMap[match];
  });
}

/**
 * Replace tokens in a given text string with values.
 *
 * @param {string} textString - The text string to replace tokens in
 * @param {Object} tokens - An object with keys as token strings and values as the replacement values. Default is an empty object. Example: {{TOKEN_NAME}: 'token value'}
 *
 * @return {string} The tokenized text
 * */
export function tokenReplacement(textString, tokens = {}, tokenValues = {}) {
  const { phoneNumber, secondaryPhoneNumber } = tokenValues;
  const defaultTokenMap = {
    '{YEAR}': new Date().getFullYear(),
    '{PHONE}': phoneNumber
      ? `<span data-tf-element-role="consent-grantor-phone">${phoneNumber}</span>`
      : '',
    '{PHONE_SECONDARY}': secondaryPhoneNumber
      ? ` and/or ${secondaryPhoneNumber}`
      : '',
  };

  // merge default tokens with any passed in
  const currentTokens = { ...defaultTokenMap, ...tokens };

  return replaceTokens(textString, currentTokens);
}

/**
 * Custom React hook to replace tokens in a given text string with values.
 *
 * @param {string} textString - The text string to replace tokens in
 * @param {Object} tokens - An object with keys as token strings and values as the replacement values. Default is an empty object. Example: {{TOKEN_NAME}: 'token value'}
 *
 * @return {string} The tokenized text
 * */

export default function useTokenReplacement(textString, additionalTokens = {}) {
  const { siteMeta } = useContext(GlobalContext);
  const { formValues, allQuestionsInForm, formStatus } = useContext(
    BasicFormWizardContext
  );

  const defaultTokenMap = useMemo(() => {
    // subject value is sometimes an object, sometimes a string
    const _selectedSubject =
      formValues[allQuestionsInForm?.[SUBJECT_0F_INTEREST]?.name];
    let _subjectLabel = _selectedSubject?.label;
    if (typeof _selectedSubject?.label === 'undefined') {
      const fishedSubject = findValueInOptions(
        _selectedSubject,
        formStatus.dynamicOptions?.[SUBJECT_0F_INTEREST]?.options || []
      );

      // SUPER EDGE CASE: session values came back but options call failed
      if (fishedSubject?.isMissing) {
        LogDebug('useTokenReplacement: subject label is missing');
        _subjectLabel = '';
      } else {
        _subjectLabel = fishedSubject?.label;
      }
    }

    return {
      '{SCHOOL_NAME}': siteMeta.siteName,
      '{SUBJECT}': _subjectLabel || '',
      '{FIRST_NAME}': formValues[allQuestionsInForm?.[FIRST_NAME]?.name] || '',
      '{LAST_NAME}': formValues[allQuestionsInForm?.[LAST_NAME]?.name] || '',
    };
  }, [
    siteMeta.siteName,
    formValues,
    allQuestionsInForm,
    formStatus.dynamicOptions,
  ]);

  // merge default tokens with any passed in
  const currentTokens = useMemo(
    () => ({ ...defaultTokenMap, ...additionalTokens }),
    [defaultTokenMap, additionalTokens]
  );

  return useMemo(() => {
    if (!textString || typeof textString !== 'string') {
      return textString;
    }
    return tokenReplacement(textString, currentTokens, {
      phoneNumber: formValues[allQuestionsInForm?.[PRIMARY_PHONE]?.name],
      secondaryPhoneNumber: formValues[SECONDARY_PHONE],
    });
  }, [textString, currentTokens]);
}
