/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { LogError } from 'utils/logging';
import { getFieldValue } from 'utils/formValuesUtils';
import GlobalContext from 'hooks/contexts/GlobalContext';
import FormContainerV1 from 'components/form-wizards/containers/FormContainerV1';
import { QUERY_PARAMS, FIELD_NAMES } from 'consts';
import { scrollTo } from 'utils/generalUtils';
import schoolListingsResultsHandler, {
  handleImpressionKeyMapping,
} from 'app-requests/clickPortalSchoolManager';
import { getUserRegionCookie } from 'utils/analyticsHelpers';
import { trackClickPortalFormSubmit } from 'utils/trackingFunctions';
import onOptionsRequest from './utils/onOptionsRequest';
import { getFormSelectionObjectsFromQuery } from './utils/getFormSelectionObjects';

const {
  PARENT_CAT_GUID_PARAM,
  CATEGORY_GUID_PARAM,
  DEGREE_GUID_PARAM,
  FILTER_KEY_PARAM,
} = QUERY_PARAMS;

const {
  CLICK_PORTAL_DEGREES,
  CLICK_PORTAL_CONCENTRATIONS,
  CLICK_PORTAL_SUBJECTS,
} = FIELD_NAMES;

function hasMissingFormValues(formValues) {
  return (
    !formValues[CLICK_PORTAL_DEGREES].value ||
    !formValues[CLICK_PORTAL_CONCENTRATIONS].value ||
    !formValues[CLICK_PORTAL_SUBJECTS].value
  );
}

export default function ClickPortalFormContainer({ Form, ...props }) {
  const router = useRouter();

  const {
    microSiteTaxonomyMap,
    activeModalId,
    actions: { updateClickPortalData, setActiveModalId },
    clickPortal: { currentSelection },
    siteMeta: { siteType },
  } = useContext(GlobalContext);

  // scroll to top when selection updates
  useEffect(() => {
    scrollTo();
  }, [currentSelection]);

  /**
   * This handles the request of school listing and mapping of its impression guids
   */
  async function handleSchoolResultsRequest(filters, meta = {}) {
    updateClickPortalData({ isLoading: true });
    const schoolListingResults = await schoolListingsResultsHandler(
      filters,
      siteType,
      /* sessionId */ undefined, // on the browser sessionId is automatically added
      {
        isPersonalized: Boolean(getUserRegionCookie()),
        originalUrl: meta.originalUrl,
      }
    );
    updateClickPortalData(schoolListingResults);
    // If no impression guids then get new impression guids
    if (!schoolListingResults.hasImpressionGuids) {
      handleImpressionKeyMapping(schoolListingResults).then(
        updateClickPortalData
      );
    }
  }

  const customSubmitHandler = (formValues) => {
    // value can be an object or string depending on field type
    const filters = {
      [CLICK_PORTAL_DEGREES]: {
        value: getFieldValue(formValues, CLICK_PORTAL_DEGREES),
      },
      [CLICK_PORTAL_CONCENTRATIONS]: {
        value: getFieldValue(formValues, CLICK_PORTAL_CONCENTRATIONS),
      },
      [CLICK_PORTAL_SUBJECTS]: {
        value: getFieldValue(formValues, CLICK_PORTAL_SUBJECTS),
      },
    };

    if (hasMissingFormValues(filters)) {
      LogError('Click Portal form tried to submit with missing form values');
      return;
    }

    const query = { ...router.query };

    query[PARENT_CAT_GUID_PARAM] = filters[CLICK_PORTAL_SUBJECTS].value;
    query[CATEGORY_GUID_PARAM] = filters[CLICK_PORTAL_CONCENTRATIONS].value;
    query[DEGREE_GUID_PARAM] = filters[CLICK_PORTAL_DEGREES].value;
    delete query[FILTER_KEY_PARAM]; // clear this out so on refresh the user has their selection

    const filterQuery = Object.keys(query)
      .filter(Boolean)
      .map((key) => `${key}=${query[key]}`)
      .join('&');

    const newPath = `/portal/search?${filterQuery}`;

    if (router.pathname !== '/portal/search') {
      router.push(newPath);
    } else if (window.history.pushState) {
      window.history.pushState({ path: newPath }, '', newPath);

      handleSchoolResultsRequest(filters, { originalUrl: router?.asPath });
    }

    if (activeModalId && activeModalId === 'clickPortalFormWizardModal') {
      setActiveModalId(null);
    }

    trackClickPortalFormSubmit(formValues);
  };

  function handleOnChange(updatedField, updatedForm) {
    // filter out fields that don't start with click-portal- and have a value
    // on microportal sites, the form has extra fields that are not part of the click portal form
    const clickPortalFields = Object.entries(updatedForm).filter(
      ([key, value]) => key.startsWith('click-portal-') && value
    );

    updateClickPortalData({
      currentSelection: updatedForm,
      shouldShowSteps: clickPortalFields.length < 3,
    });
  }

  /**
   * On route changes request new result set
   */
  useEffect(() => {
    const handleStart = (url) => {
      if (!url.includes('/search')) {
        return;
      }

      try {
        const query = new URLSearchParams(url.split('?').pop());
        const newFormValues = getFormSelectionObjectsFromQuery(
          query,
          microSiteTaxonomyMap
        );

        const filters = {
          filterKey: query.get(FILTER_KEY_PARAM),
          ...newFormValues,
        };

        updateClickPortalData({
          isLoading: true,
          currentSelection: newFormValues,
          filterKey: filters.filterKey,
          shouldShowSteps: false,
        });
        handleSchoolResultsRequest(filters, { originalUrl: router?.asPath });
      } catch (error) {
        LogError(
          `Click Portal: Failed to load results via route change: ${error.message}`,
          { url }
        );
      }
    };

    router.events.on('routeChangeStart', handleStart);

    return () => {
      router.events.off('routeChangeStart', handleStart);
    };
  }, [router]);

  return (
    <FormContainerV1
      {...props}
      onChange={handleOnChange}
      disableLogging
      disableSideEffects
      disableAutoSelect
      customSubmitHandler={customSubmitHandler}
      onOptionsRequest={(request) =>
        onOptionsRequest(request, microSiteTaxonomyMap)
      }
      Form={Form}
    />
  );
}

ClickPortalFormContainer.propTypes = {
  formOptions: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
};
