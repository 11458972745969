/**
 * TODO: [T1-9336] write end to end test for this form once in production
 * This is the full page form for MicroPortal
 */
import React, { useContext } from 'react';
import GlobalContext from 'hooks/contexts/GlobalContext';
import FullPageFormWizard from 'components/form-wizards/fullPageFormWrapper/FullPageFormWizard';
import useMicroPortalFormHandler from './useMicroPortalFormHandler';

/**
 * The Form component that is feed into the FormContainer.
 * This Form's props will be all form utilities and form values.
 */
export default function MicroPortalFullPageForm(props) {
  const {
    microPortal: { primaryFormSubmitPollingHelper },
    actions: { updateMicroPortalData },
  } = useContext(GlobalContext);

  const { onLogProgress, onOptionsRequest, onFormSubmit, onMoveToNextStep } =
    useMicroPortalFormHandler({
      updateMicroPortalData,
      primaryFormSubmitPollingHelper,
    });

  const buttonText = {
    first: 'Continue',
    last: 'Submit',
    disclaimer: 'Request Info',
  };

  return (
    <FullPageFormWizard
      {...props}
      onMoveToNextStep={onMoveToNextStep}
      disableSideEffects
      customSubmitHandler={onFormSubmit}
      onOptionsRequest={onOptionsRequest}
      onLogProgress={onLogProgress}
      className="isMicroPortal"
      buttonText={buttonText}
    />
  );
}
