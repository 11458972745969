import get from 'lodash/get';
import { QUESTION_IDS } from 'consts';
import {
  isQuestionOptionsCacheable,
  isSessionStorageEnabled,
} from 'utils/generalUtils';
import { TRIAD_PROXY_ROUTE } from 'app-requests/apiConstants';
import { retryableRequest } from 'utils/request';
import { questionsToMap } from 'utils/formValuesUtils';
import {
  getQuestionOptionsPayload,
  GetQuestionOptionsParsedResponse,
  GetQuestionOptionsRawResponse,
} from 'types';
import { LogWarning } from 'utils/logging';
import { getUserSessionId } from 'utils/analyticsHelpers';

const { SUBJECT_0F_INTEREST } = QUESTION_IDS;

/**
 * @summary given the current state of the form and the current school we may need to get options for a question
 */
export function getQuestionOptions(
  payload: getQuestionOptionsPayload,
  schoolCode: string,
  variant: string,
  endpoint = '/questionOption',
  pageTaxonomyValues = {}
): GetQuestionOptionsParsedResponse {
  const replies = get(payload, 'questionReplies', []).map(
    ({ questionAnswer, questionId }) => ({
      questionAnswer,
      questionId,
    })
  );

  const neededOptions = get(payload, 'requestedOptions', []);
  const endpointUrl = `${TRIAD_PROXY_ROUTE}${endpoint}`;
  let cacheKey = '';

  // if options request is just for subject then cache
  if (isQuestionOptionsCacheable(neededOptions)) {
    // not taking variant into account here because we are only caching for subject which is the same on all variants
    cacheKey = `${schoolCode}_questionOptions_${SUBJECT_0F_INTEREST}`;
    let cachedValue;

    if (isSessionStorageEnabled()) {
      cachedValue = sessionStorage.getItem(cacheKey);
    }

    // remove bad cache
    if (cachedValue) {
      const parsedCachedValue = JSON.parse(cachedValue);
      const questionsInCache = parsedCachedValue?.questions;
      const optionsInCache = questionsInCache?.[0]?.options?.[0]?.options;

      if (questionsInCache?.length === 0) {
        LogWarning(`Cached value found for ${cacheKey} but it has no options`);
        sessionStorage.removeItem(cacheKey);
      }

      if (optionsInCache?.length === 0) {
        LogWarning(`Cached value found for ${cacheKey} but it has no options`);
        sessionStorage.removeItem(cacheKey);
      }
    }
  }

  if (!getUserSessionId()) {
    LogWarning('getQuestionOptions: No user session id found');
  }

  return retryableRequest({
    method: 'post',
    url: endpointUrl,
    cacheKey,
    responseTimeout: 10000,
    deadlineTimeout: 10000,
    body: {
      schoolCode,
      templateName: variant,
      requestedOptions: neededOptions,
      questionReplies: replies,
      pageTaxonomyValues,
    },
  })
    .then(({ questions }: GetQuestionOptionsRawResponse) => {
      return questionsToMap(questions);
    })
    .catch((error: Error) => {
      throw error;
    });
}
