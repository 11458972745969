import { useEffect, useContext } from 'react';
import { getApplicationLinks } from 'app-requests/triadms-apis/getApplicationLinks';
import GlobalContext from 'hooks/contexts/GlobalContext';
import { getLeadGuid } from 'utils/analyticsHelpers';
import { LogWarning } from 'utils/logging';

export default function useApplicationLinks(): void {
  const {
    sessionInfo: { pageViewId, userSessionId },
    siteMeta: { schoolCode },
    actions: { updateUserData },
    userData: { applicationLink },
  } = useContext(GlobalContext);

  useEffect(() => {
    if (applicationLink) {
      return;
    }

    if (!userSessionId || !schoolCode || !pageViewId) {
      LogWarning('Missing userSessionId, schoolCode, or pageViewId', {
        userSessionId,
        schoolCode,
        pageViewId,
      });
      return;
    }

    const leadGuid = getLeadGuid();

    getApplicationLinks({
      pageViewId,
      sessionId: userSessionId,
      schoolCode,
      leadGuid,
    }).then((links) => {
      if (links) {
        updateUserData(links);
      }
    });
  }, []);
}
