/* eslint-disable max-len */
/**
 * @summary This is the component that will set the Head of the site.
 *  This component will injected the needed SEO meta data and other global
 *  scripts needed for the site/page.
 */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Head from 'next/head';
import { initGtm } from 'utils/thirdPartyScripts';
import { pagePropsObj } from 'utils/propTypes';
import { getPageTitle } from 'utils/siteHeadHelpers';
import isDevelopment from 'utils/isDevelopment';
import KetchScript from './KetchScript';

function SiteHead(props) {
  const {
    meta = {},
    availableThemes,
    title,
    configEnv,
    abTestingClientKey,
    taxonomyValues,
  } = props;

  const {
    domain,
    favicon,
    googleTagManagerSiteId,
    robots,
    ogImg,
    siteDescription,
    siteName,
    theme = 'default-theme',
    url,
    seoTitle,
    seoDescription,
    seoKeywords,
    schoolCode,
    canonicalDomain,
    siteType,
    isDraftMode = false,
    originSchoolCode = null,
    isABTestingEnabled,
    ketch,
  } = meta;

  const description = seoDescription || siteDescription;
  const pageTitle = getPageTitle(seoTitle, title, siteName);
  const canonicalUrl = `https://${canonicalDomain}${url}`;
  const robotsText =
    configEnv === 'production' && !domain.includes('pre-prod') && !isDraftMode
      ? robots
      : 'noindex, nofollow';
  const isProductionAnalytics = configEnv === 'production';

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {favicon && (
        <>
          <link
            rel="icon"
            href={get(favicon, 'sizes.faviconBrowser.url')}
            sizes="32x32"
          />
          <link
            rel="apple-touch-icon"
            href={get(favicon, 'sizes.faviconIOS.url')}
            sizes="180x180"
          />
          <link
            rel="icon"
            href={get(favicon, 'sizes.faviconWindowsTile.url')}
            sizes="270x270"
          />
        </>
      )}
      <meta name="description" content={description} />
      <meta name="robots" content={robotsText} />
      <meta name="googlebot" content={robotsText} />
      <meta name="bingbot" content={robotsText} />
      <link rel="canonical" href={canonicalUrl} />
      {/* Open Graph meta data, used for social media cards */}
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`https://${domain}${url}`} />
      <meta property="og:site_name" content={siteName} />
      {ogImg && <meta property="og:image" content={ogImg} />}
      {seoKeywords && <meta name="keywords" content={seoKeywords} />}
      {/* <link rel="manifest" href="/manifest.json" /> */}

      <script
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [{
            school_code: "${schoolCode}",
            isClickPortal: ${siteType === 'clickPortal'},
            origin_school_code: "${originSchoolCode}",
            taxonomy_parent_category: "${taxonomyValues?.parentCategories?.join(
              '|'
            )}",
            taxonomy_category: "${taxonomyValues?.categories?.join('|')}",
            taxonomy_degree: "${taxonomyValues?.degrees?.join('|')}",
            taxonomy_programs: "${taxonomyValues?.programs?.join('|')}"
          }];

          if(${!isProductionAnalytics}) {
            window.dataLayer.push({debug_mode: 1, traffic_type: "internal"});
          }
          `,
        }}
      />

      <script
        dangerouslySetInnerHTML={{
          __html: `window.isABTestingEnabled = ${isABTestingEnabled}`,
        }}
      />

      {!isDevelopment() && (
        <script
          dangerouslySetInnerHTML={{
            __html: initGtm(googleTagManagerSiteId, { schoolCode }),
          }}
        />
      )}

      {/* yarn build:themes make these css files. They then get injected into .env which is passed to this component via the page */}
      {'<!-- Render the theme for this request -->'}
      {!isDevelopment() && (
        <>
          <link
            rel="preload"
            href={`/_next/static/css/${availableThemes[theme]}`}
            as="style"
          />
          <link
            rel="stylesheet"
            href={`/_next/static/css/${availableThemes[theme]}`}
          />
        </>
      )}
      <KetchScript isEnabled={ketch.isEnabled} propertyId={schoolCode} />
      {/* A/B Testing Enabled From Wordpress */}
      {isABTestingEnabled && [
        // Set Configs
        <script
          key="ab-testing-config"
          dangerouslySetInnerHTML={{
            __html: `
              window.growthbook_config = window.growthbook_config || {};
              window.growthbook_queue = window.growthbook_queue || [];
              window.growthbook_config.navigateDelay = 400;
              // Disable streaming Updates
              window.growthbook_config.backgroundSync = false;
              window.growthbook_queue.push((gb) => {
                window.triadGrowthBookAPI = gb;
              })
            `,
          }}
        />, // Load GrowthBook
        <script
          key="ab-testing"
          data-client-key={abTestingClientKey}
          src="https://cdn.jsdelivr.net/npm/@growthbook/growthbook/dist/bundles/auto.min.js"
        />, // Anti-Flicker Script
        <script
          key="ab-testing-anti-flicker"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w, d, c) {
                var s = d.documentElement;
                s.className += ' async-hide';
                setTimeout(function() {
                  s.className = s.className.replace(' async-hide', '');
                }, c);
              })(window, document, 1500);
            `,
          }}
        />,
      ]}
    </Head>
  );
}

SiteHead.propTypes = {
  title: PropTypes.string,
  configEnv: PropTypes.string,
  meta: pagePropsObj.meta.isRequired,
  availableThemes: PropTypes.objectOf(PropTypes.string).isRequired,
  abTestingClientKey: PropTypes.string,
};

export default SiteHead;
