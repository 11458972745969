/**
 * @summary The Default Header For the Landing Pages.
 */
import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { CtaPropTypes } from 'utils/propTypes';
import cx from 'utils/classnames';
import Modal from 'components/base/modals/Modal';
import Questionnaire from 'components/form-wizards/Questionnaire';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import CtaButton from 'components/blocks/ctaButton/CtaButton';
import ClickPortalHeaderNav from 'components/sections/headers/click-portal-header/ClickPortalHeaderNav';
import GlobalContext from 'hooks/contexts/GlobalContext';

function ClickPortalHeader({ cta, headertext = '', siteName, innerRef }) {
  const headerClass = cx({
    header: true,
    'header--clickPortal': true,
    'header--hasHeaderText': !!headertext,
  });

  const { questionnaires } = useContext(GlobalContext);

  return (
    <header className={headerClass} ref={innerRef}>
      <Modal
        id="clickPortalFormWizardModal"
        className="modal--isClickPortalWizard"
      >
        <Questionnaire {...questionnaires?.fullPage} disableTrustedForm />
      </Modal>
      <div className="header__inner">
        <div className="header__logo">
          <span className="isVisuallyHidden">{siteName}</span>
        </div>

        {headertext && (
          <div className="header__secondary">
            <Wysiwyg content={headertext} />
          </div>
        )}
        <div className="header__nav">
          <ClickPortalHeaderNav />
        </div>
        {cta && cta.useCTA && (
          <div className="header__secondary">
            <CtaButton {...cta} />
          </div>
        )}
      </div>
    </header>
  );
}

ClickPortalHeader.propTypes = {
  headertext: PropTypes.string,
  cta: CtaPropTypes,
  siteName: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export default forwardRef((props, ref) => (
  <ClickPortalHeader {...props} innerRef={ref} />
));
