import React, { useContext } from 'react';
import GlobalContext from 'hooks/contexts/GlobalContext';
import cx from 'utils/classnames';

interface Props {
  isStatic?: boolean;
  title?: string;
}

export default function SubmissionCart({
  isStatic,
  title,
}: Props): React.JSX.Element {
  const {
    microPortal: { submittedSchools, userFirstName },
  } = useContext(GlobalContext);

  return (
    <div
      className={cx('submissionCart', {
        'submissionCart--isStatic': isStatic,
      })}
    >
      <div className="submissionCart__recap">
        <div className="submissionCart__title">
          <h2>
            {`Thank you ${userFirstName}, your school${
              submittedSchools.length > 1 ? 's' : ''
            } will contact you shortly`}
          </h2>
        </div>
        <ul className="submissionCart__list">
          {submittedSchools.map((school) => (
            <li className="submissionCart__item" key={school.id}>
              <img
                src={school.schoolLogo.mobile}
                alt={school.label}
                className="submissionCart__itemImage"
              />
              <div className="submissionCart__itemLabel">{school.label}</div>
            </li>
          ))}
        </ul>
      </div>
      {!isStatic && (
        <div className="submissionCart__loader">
          <img src="/theme-assets/ellipsisLoader.svg" alt="Loading..." />
        </div>
      )}
      {isStatic && (
        <div className="submissionCart__subHeader">
          <h1 className="submissionCart__subHeaderTitle">
            <span className="submissionCart__subHeaderPreamble">
              Below are additional schools with
            </span>
            <span className="submissionCart__subHeaderTitleText">{title}</span>
          </h1>
        </div>
      )}
    </div>
  );
}
