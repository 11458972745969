import React, { useEffect, useContext, memo } from 'react';
import PropTypes from 'prop-types';
import getComponent from 'components/FORM_MAP';
import { numOrStrPropType, StepsPropType } from 'utils/propTypes';
import { trackWizardView } from 'utils/trackingFunctions';
import GlobalContext from 'hooks/contexts/GlobalContext';
import { initTrustedForm, initJornaya } from 'utils/thirdPartyScripts';
import { AB_TEST_TYPE_MAP } from 'consts';

const LeadIdField = memo(() => (
  <input id="leadid_token" name="universal_leadid" type="hidden" value="" />
));

export default function Questionnaire(props) {
  const {
    abTests,
    windowSize: { currentBreakpoint },
  } = useContext(GlobalContext);

  // TODO: make a hook for AB tests to handle things more seamlessly
  const testQuestionnaire = abTests[AB_TEST_TYPE_MAP.QUESTIONNAIRE];
  const {
    component,
    mobileComponent,
    isFullPage = false,
    disableTrustedForm,
    ...questionnaireData
  } = testQuestionnaire || props;

  const formComponent =
    currentBreakpoint === 'desktop' ? component : mobileComponent;

  const Form = getComponent(formComponent);

  useEffect(() => {
    if (!disableTrustedForm) {
      initTrustedForm();
    }

    initJornaya();
  }, []);

  useEffect(() => {
    trackWizardView(props.schoolCode, questionnaireData.variant);
  }, [questionnaireData.variant]);

  const onSubmit = (e) => {
    e.preventDefault();
  };

  if (!currentBreakpoint) {
    return null;
  }

  return (
    <form className="questionnaire" onSubmit={onSubmit}>
      <Form {...questionnaireData} isFullPage={isFullPage} />
      <LeadIdField />
    </form>
  );
}

Questionnaire.propTypes = {
  component: PropTypes.string,
  id: numOrStrPropType,
  isFullPage: PropTypes.bool,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  schoolCode: PropTypes.string,
  steps: StepsPropType,
};
