import React from 'react';

interface GoogleMapProps {
  mapUrl?: string;
}

export function GoogleMap(props: GoogleMapProps): JSX.Element | null {
  const { mapUrl } = props;

  if (!mapUrl) {
    return null;
  }

  return (
    <img
      src={mapUrl}
      alt="user's google map marker location"
      data-testid="google-map-for-user-address"
    />
  );
}
