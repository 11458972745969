/**
 * @summary this file deals with everything around click portal schools. From requesting schools to managing their impression tracking
 */
import {
  CLICK_PORTAL_SEARCH_FILTERS,
  QUERY_PARAMS,
  SITE_TYPE,
  SCHOOL_IMPRESSION_TYPES,
} from 'consts';
import { GetSchoolListingRawResponse } from 'types';
import get from 'lodash/get';
import isBrowser from 'utils/isBrowser';
import { wait } from 'utils/generalUtils';
import { getUserSessionId } from 'utils/analyticsHelpers';
import { getFormSelectionObjectsFromQuery } from 'components/form-wizards/click-portal-search/utils/getFormSelectionObjects';
import { getSchoolLogoUrlMap } from 'utils/imageHelpers';
import promiseDebounce from 'utils/promiseDebounce';
import { APIError } from 'utils/errors/APIError';
import { APITransformError } from 'utils/errors/APITransformError';
import { LogError, LogWarning } from '../utils/logging';
import request from '../utils/request';
import { TRIAD_PROXY_ROUTE, TRIADMS_BACKEND } from './apiConstants';

interface GetSchoolListingResults {
  hasImpressionGuids: boolean;
  title: string;
  subTitle: string;
  impressionCacheKey?: string;
  results: {
    id: string;
    name: string;
    schoolLogoUrl: string;
    description: string;
    rating: number | null;
    url: string | null;
    learningType: string | null;
    isOffsiteConversion: boolean;
    highlights: string[];
    selectedProgram: Record<string, unknown>;
    otherPrograms: Record<string, unknown>[];
    filteredProgramCount: number | null;
    programCount: number | null;
    matchingCategory: string | null;
    schoolCode: string | null;
    revenue: number;
    impressionGuid: string | null | undefined;
    filterTagName: string;
  }[];
  hasTags: boolean;
  filterKeyDerivedValues: {
    hasDerivedValues: boolean;
    parent_category_guid: string | null;
    degree_level_guid: string | null;
    category_guid: string | null;
  };
}

interface RecordViewPayload {
  method: string;
  url: string;
  body: {
    viewLocation: string;
    schoolImpressionGuid: string;
    schoolDegreeInfoGuids: string[];
  };
}

type SchoolFilters = Record<
  CLICK_PORTAL_SEARCH_FILTERS,
  { value: string | undefined } | string | undefined
>;

const { DEGREES, CONCENTRATIONS, SUBJECTS, FILTER_KEY } =
  CLICK_PORTAL_SEARCH_FILTERS;

const {
  MP_REQUEST_IMPRESSIONS,
  CP_REQUEST_IMPRESSIONS,
  CP_REQUEST_IMPRESSIONS_CACHE_KEY,
} = SCHOOL_IMPRESSION_TYPES;

export const schoolImpressionPayloadQueue: Record<string, RecordViewPayload> =
  {};

/**
 * @summary this will get the click portal school results cache key
 */
export function generateSchoolListingCacheKey(filters: SchoolFilters): string {
  Object.keys(filters).forEach((key) => {
    const _key = key as CLICK_PORTAL_SEARCH_FILTERS;
    if (![DEGREES, CONCENTRATIONS, SUBJECTS, FILTER_KEY].includes(_key)) {
      LogWarning(
        'We have added an Unknown filter that needs to be present in the cache key'
      );
    }
  });

  const degreeValue =
    filters[DEGREES] && typeof filters[DEGREES] !== 'string'
      ? filters[DEGREES]?.value
      : null;

  const subjectValue =
    filters[SUBJECTS] && typeof filters[SUBJECTS] !== 'string'
      ? filters[SUBJECTS]?.value
      : null;

  const concentrationValue =
    filters[CONCENTRATIONS] && typeof filters[CONCENTRATIONS] !== 'string'
      ? filters[CONCENTRATIONS]?.value
      : null;

  const filterKey = filters[FILTER_KEY];

  return [degreeValue, subjectValue, concentrationValue, filterKey]
    .map((value) => value || 'undefined')
    .join('|');
}

/**
 * @summary its possible we don't have the impression key at the time we render the  schools. In this case queue up the impressions
 * @param {Object} programPayload - see trackClickPortalImpression on the payload we send to backend
 * @private
 */
function _addToImpressionQueue(
  schoolGuid: string,
  programPayload: RecordViewPayload
): void {
  if (schoolGuid && programPayload.body) {
    schoolImpressionPayloadQueue[schoolGuid] = programPayload;
  }
}

const schoolImpressionBatch = new Set<
  Omit<RecordViewPayload, 'schoolDegreeInfoGuids'>
>();

function _processBatchSchoolImpression(): Promise<void> {
  const payloads = Array.from(schoolImpressionBatch);
  schoolImpressionBatch.clear();
  const locations: Record<string, string[]> = {};
  payloads.forEach(({ body: { viewLocation, schoolImpressionGuid } }) => {
    locations[viewLocation] = locations[viewLocation] || [];
    locations[viewLocation].push(schoolImpressionGuid);
  });

  return Promise.all(
    Object.keys(locations).map((location) => {
      return request({
        method: 'post',
        url: `${TRIAD_PROXY_ROUTE}/ClickPortal/RecordView`,
        body: {
          viewLocation: location,
          schoolImpressionGuids: locations[location],
        },
      });
    })
  );
}

const _debouncedSchoolImpressionBatch = promiseDebounce(
  _processBatchSchoolImpression,
  3000
) as () => Promise<unknown>;

function _batchSchoolImpression(payload: RecordViewPayload): Promise<unknown> {
  if (!payload.body.schoolImpressionGuid) {
    LogError('School impression guid is required');
    return Promise.resolve();
  }

  if (payload.body.schoolDegreeInfoGuids?.length > 0) {
    return request({
      method: 'post',
      url: `${TRIAD_PROXY_ROUTE}/ClickPortal/RecordProgramView`,
      body: {
        viewLocation: payload.body.viewLocation,
        schoolImpressionGuid: payload.body.schoolImpressionGuid,
        schoolDegreeInfoGuids: payload.body.schoolDegreeInfoGuids,
      },
    });
  }

  schoolImpressionBatch.add(payload);
  return _debouncedSchoolImpressionBatch();
}

/**
 * @summary its possible that some impressions were queued up before the schools were rendered. In this case send them now.
 */
function _processImpressionQueue(
  schools: GetSchoolListingResults['results'] = []
): void {
  try {
    schools.forEach((school) => {
      const payload = schoolImpressionPayloadQueue[school.id];

      if (payload) {
        const _payload = {
          ...payload,
          body: {
            ...payload.body,
            schoolImpressionGuid: school.impressionGuid || '',
          },
        };

        _batchSchoolImpression(_payload).catch((error: Error) => {
          LogError(error, {
            impressionGuid: school.impressionGuid,
          });
        });
      }

      delete schoolImpressionPayloadQueue[school.id];
    });
  } catch (error: unknown) {
    LogError(`Failed to process impression Queue ${(error as Error).message}`);
  }
}

/**
 * @summary Determines if impressions should be requested based on portal type and session information
 */
export function shouldRequestImpressions(
  isMicroPortal: boolean,
  isOnBrowser: boolean,
  sessionId: string
): boolean {
  if (isMicroPortal) {
    return true;
  }

  if (!isOnBrowser) {
    return false;
  }

  if (sessionId) {
    return true;
  }

  return false;
}

/**
 * @summary this will get the click portal school results
 */
async function _getSchoolListings(
  schoolFilters: SchoolFilters,
  siteType: string,
  sessionId: string,
  meta: {
    isPersonalized: boolean;
    geoLocation: Record<string, unknown>;
    originalUrl: string;
    queryParams?: Record<string, unknown>;
    schoolCode: string;
  }
): Promise<GetSchoolListingResults> {
  const {
    isPersonalized = false,
    geoLocation = {},
    originalUrl = '',
    queryParams,
    schoolCode,
  } = meta || {};
  const path = isBrowser() ? TRIAD_PROXY_ROUTE : TRIADMS_BACKEND;
  const isMicroPortal = siteType === SITE_TYPE.MICRO_PORTAL;
  const endpointUrl =
    siteType === 'clickPortal'
      ? `${path}/ClickPortal/GetSchoolListings`
      : `${path}/microportal/GetMPSchoolListings`;
  // Note this will only be cached on browser
  const cacheKey = isBrowser()
    ? generateSchoolListingCacheKey(schoolFilters)
    : null;

  const _shouldRequestImpressions = shouldRequestImpressions(
    isMicroPortal,
    isBrowser(),
    isBrowser() ? getUserSessionId() : null
  );

  // Check if the filterKey contains '&' and log an error
  // error logs are showing filterKeys being passed that are concatenated with other params
  const filterKey = schoolFilters[FILTER_KEY];

  if (
    filterKey &&
    typeof filterKey === 'string' &&
    (filterKey.includes('&') || filterKey.includes('&amp;'))
  ) {
    LogError('Invalid FilterKey: contains the & character', {
      filterKey,
      originalUrl,
    });
  }

  let impressionType;

  if (isMicroPortal) {
    impressionType = MP_REQUEST_IMPRESSIONS;
  } else if (_shouldRequestImpressions) {
    impressionType = CP_REQUEST_IMPRESSIONS;
  } else {
    impressionType = CP_REQUEST_IMPRESSIONS_CACHE_KEY;
  }

  let _queryParams: { name: string; value: unknown }[] | undefined;

  // on micro portal we will get these server side. We don't cache this page so its save
  // on the browser this happens in the base request function for all requests to triad
  if (queryParams && Object.keys(queryParams).length > 0) {
    _queryParams = Object.keys(queryParams).map((key) => ({
      name: key,
      value: queryParams[key],
    }));
  }

  return request({
    cacheKey,
    method: 'post',
    url: endpointUrl,
    body: {
      impressionType,
      FilterKey: filterKey || null,
      DegreeType:
        schoolFilters[DEGREES] && typeof schoolFilters[DEGREES] !== 'string'
          ? schoolFilters[DEGREES]?.value
          : null,
      ParentCategory:
        schoolFilters[SUBJECTS] && typeof schoolFilters[SUBJECTS] !== 'string'
          ? schoolFilters[SUBJECTS]?.value
          : null,
      Category:
        schoolFilters[CONCENTRATIONS] &&
        typeof schoolFilters[CONCENTRATIONS] !== 'string'
          ? schoolFilters[CONCENTRATIONS]?.value
          : null,
      sessionId,
      includeGeoLocation: isPersonalized, // when called from browser this will tell server to include geoLocation
      // See [...endpoints].js for how this gets added from browser
      geoLocation: isBrowser() ? {} : geoLocation, // when called from server we pass it in directly,
      originalUrl,
      requestType: isMicroPortal ? 'MicroClickportal' : null,
      queryParams: _queryParams,
      schoolCode,
    },
  })
    .then(async (res: GetSchoolListingRawResponse) => {
      if (!res.IsValid) {
        throw new APIError(
          res.Errors?.[0] ||
            'Get School Listing API Failed but no backend error returned',
          500,
          'getSchoolListings'
        );
      }

      const isFromCache = Boolean(res.cacheKey);

      const results = res.Listings.map((school) => ({
        id: school.value,
        name: school.label,
        schoolLogoUrl: getSchoolLogoUrlMap(school.schoolImages),
        description:
          get(school, 'schoolDesc[0]') ||
          'Description not available please check back soon.',
        rating: school.rating || null,
        url: school.destinationUrl || null,
        learningType: school.learningEnvironment || null,
        isOffsiteConversion: school.IsOffsiteConversion || false,
        highlights: [school.highlights || ''],
        selectedProgram: school.selectedProgram || {},
        otherPrograms: school.programs || [],
        filteredProgramCount: school.filteredProgramCount || null,
        programCount: school.programCount || null,
        matchingCategory: school.matchingCategory || null,
        schoolCode: school.schoolCode || null,
        revenue: school.Revenue || 0,
        // If not from cache and their is an impressionGuid set to GUID else null out
        impressionGuid: isFromCache ? null : get(school, 'impressionGuid', ''),
        filterTagName: school.FilterTagName || '',
      }));

      const hasImpressionGuids = !isFromCache && _shouldRequestImpressions;

      if (!hasImpressionGuids && !res.ImpressionKey) {
        LogError(
          'School results are loaded but their are both no impression guids and we have no cache key to fetch them.'
        );
      }

      if (isFromCache) {
        await wait(500);
      }

      return {
        hasImpressionGuids,
        title: res.Headline1 || '',
        subTitle: res.Headline2 || '',
        impressionCacheKey: res.ImpressionKey || '',
        results,
        hasTags: res.HasTags || false,
        filterKeyDerivedValues: {
          hasDerivedValues: Boolean(
            res.ParentCategory && res.DegreeType && res.Category
          ),
          [QUERY_PARAMS.PARENT_CAT_GUID_PARAM]: res.ParentCategory || null,
          [QUERY_PARAMS.DEGREE_GUID_PARAM]: res.DegreeType || null,
          [QUERY_PARAMS.CATEGORY_GUID_PARAM]: res.Category || null,
        },
      };
    })
    .catch((error: Error) => {
      if (error instanceof APIError) {
        throw error;
      }
      throw new APITransformError(error.message, 'getSchoolListings');
    });
}

/**
 * @summary this function will request impression GUIDs from the backend and attach them to the school listings results
 */
export function handleImpressionKeyMapping(
  schoolListingResults: GetSchoolListingResults
): Promise<GetSchoolListingResults> {
  const { impressionCacheKey, results } = schoolListingResults;

  if (!impressionCacheKey) {
    LogWarning('Impression Key not returned from backend');
    return Promise.resolve(schoolListingResults);
  }

  return request({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}/ClickPortal/GetSchoolImpressions`,
    body: {
      impressionKey: impressionCacheKey,
    },
  }).then(
    ({
      SchoolImpressions,
    }: {
      SchoolImpressions: { SchoolGuid: string; value: string }[];
    }) => {
      const mappedResults = results.map((result) => {
        const mappedResult = { ...result };
        const impressionGuid = SchoolImpressions?.find(
          (impression) => impression.SchoolGuid === result.id
        )?.value;

        mappedResult.impressionGuid = impressionGuid;
        return mappedResult;
      });

      _processImpressionQueue(mappedResults);
      return { ...schoolListingResults, results: mappedResults };
    }
  );
}

/**
 * @deprecated
 * @summary this function will track and impression on the backend
 */
export function trackClickPortalImpression({
  schoolId,
  impressionGuid,
  programGuids,
  viewLocation,
}: {
  schoolId: string;
  impressionGuid: string;
  programGuids: string[];
  viewLocation: string;
}): void {
  // IF CHANGING THIS, CHANGE processQueue in schoolImpressionQueue
  const payload = {
    method: '',
    url: '',
    body: {
      viewLocation,
      schoolImpressionGuid: impressionGuid,
      schoolDegreeInfoGuids: programGuids,
    },
  };

  if (!impressionGuid) {
    _addToImpressionQueue(schoolId, payload);
    return;
  }

  _batchSchoolImpression(payload).catch((error: Error) => {
    LogError(error, { impressionGuid });
    // don't throw, we don't want any UI error due to failing to track
  });
}

/**
 * @summary get school listing from query
 */
export default async function schoolListingsResultsHandler(
  filters: SchoolFilters,
  siteType: string,
  sessionId: string,
  meta: {
    isPersonalized: boolean;
    geoLocation: Record<string, unknown>;
    originalUrl: string;
    queryParams: Record<string, unknown>;
    schoolCode: string;
  }
): Promise<
  GetSchoolListingResults & {
    error: string;
    message: string;
    isLoading: boolean;
  }
> {
  const {
    isPersonalized = false,
    geoLocation = {},
    originalUrl = '',
    queryParams = {},
    schoolCode,
  } = meta || {};

  const schoolResults = await _getSchoolListings(
    filters,
    siteType,
    sessionId,
    /* meta */ {
      isPersonalized,
      geoLocation,
      originalUrl,
      queryParams,
      schoolCode,
    }
  );

  return {
    ...schoolResults,
    message: '',
    error: '',
    isLoading: false,
  };
}

/**
 * @summary get school listing from query
 */
export async function getSchoolListingResultsFromQuery(
  query: Record<string, string>,
  microSiteTaxonomyMap: Record<string, unknown>,
  meta: {
    isPersonalized: boolean;
    geoLocation: Record<string, unknown>;
    originalUrl: string;
    queryParams: Record<string, unknown>;
    sessionId: string;
    siteType: string;
    schoolCode: string;
  }
): Promise<
  GetSchoolListingResults & { currentSelection?: Record<string, unknown> }
> {
  const {
    isPersonalized,
    geoLocation,
    sessionId,
    siteType,
    originalUrl,
    queryParams,
    schoolCode,
  } = meta || {};
  if (!sessionId && siteType === 'microPortal') {
    LogError('no sessionId on Microportal');
  }

  try {
    const filters = {
      [FILTER_KEY]: query[QUERY_PARAMS.FILTER_KEY_PARAM],
      ...getFormSelectionObjectsFromQuery(query, microSiteTaxonomyMap),
    };
    const results = await schoolListingsResultsHandler(
      filters,
      siteType,
      sessionId,
      /* meta */ {
        isPersonalized,
        geoLocation,
        originalUrl,
        queryParams,
        schoolCode,
      }
    );
    const hasFormQueryParams =
      query[QUERY_PARAMS.PARENT_CAT_GUID_PARAM] &&
      query[QUERY_PARAMS.DEGREE_GUID_PARAM] &&
      query[QUERY_PARAMS.CATEGORY_GUID_PARAM];
    let currentSelection;

    // Its possible on the server we get form values to select from API
    // This is also done on the browser in the GlobalReducer in case of client side routing
    // TODO: [T1-9137] lets consolidate this logic and remove it from the reducer.
    //       When getting and setting the results on the browser lets
    //       just set the current selection then as well
    if (results?.filterKeyDerivedValues?.hasDerivedValues) {
      currentSelection = getFormSelectionObjectsFromQuery(
        results.filterKeyDerivedValues,
        microSiteTaxonomyMap
      );
    } else if (hasFormQueryParams) {
      currentSelection = getFormSelectionObjectsFromQuery(
        query,
        microSiteTaxonomyMap
      );
    }
    // it's possible that the backend sends a default result set back if the selection was invalid
    // so unless all three values are still populated, assume there's no currentSelection

    if (
      currentSelection &&
      Object.values(currentSelection).every((selection) => selection?.value)
    ) {
      return {
        ...results,
        currentSelection,
      };
    }

    return results;
  } catch (error: unknown) {
    if (error instanceof APIError) {
      throw error;
    }

    if (error instanceof APITransformError) {
      throw error;
    }

    throw new APITransformError(
      (error as Error).message,
      'getSchoolListingResultsFromQuery'
    );
  }
}
