import React, { useContext, useEffect } from 'react';
import GlobalContext from 'hooks/contexts/GlobalContext';
import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';
import { FormFieldProps } from 'types';
import {
  getMicroPortalDisclaimerText,
  getTCPAOnlyListingOptions,
} from 'utils/form-utils/schoolListingHelpers';
import Loader from 'components/base/loader/Loader';
import { FIELD_NAMES, QUESTION_IDS } from 'consts';
import { recordSchoolView } from 'app-requests/triadms-apis/microPortalImpressionTracking';
import Disclaimer from '../disclaimer/Disclaimer';

const { SECONDARY_PHONE } = FIELD_NAMES;
const { PRIMARY_PHONE } = QUESTION_IDS;

export function DynamicDisclaimer(
  props: FormFieldProps<boolean | null>
): React.ReactNode {
  const {
    siteMeta: {
      disclaimerText,
      disclaimerSuccessText,
      sharedDisclaimerOptInText,
    },
    actions,
  } = useContext(GlobalContext);

  const {
    formStatus: { isProcessing, dynamicOptions },
    formValues,
    allQuestionsInForm,
  } = useContext(BasicFormWizardContext);

  const tCPAOnlySchools = getTCPAOnlyListingOptions(dynamicOptions);
  const tcpaOnlyImpressionGuids = tCPAOnlySchools
    .map((school) => school.impressionGuid)
    .join('-');

  const requiresCheckbox = tCPAOnlySchools.some(
    (school) => school.hasTcpaCheckbox
  );

  useEffect(() => {
    if (tcpaOnlyImpressionGuids) {
      recordSchoolView(
        tCPAOnlySchools.map((school) => school.impressionGuid),
        'MicroportalTCPAOnlySchool'
      );
    }
  }, [tcpaOnlyImpressionGuids]);

  useEffect(() => {
    actions.updateGlobalFlags({ isLoading: isProcessing });

    if (isProcessing) {
      props.onChange(null, { name: props.name });
    } else if (!requiresCheckbox) {
      props.onChange(true, { name: props.name });
    }
  }, [isProcessing, requiresCheckbox]);

  let phoneNumber = '';
  let secondaryPhoneNumber = '';
  if (typeof formValues[SECONDARY_PHONE] === 'string') {
    secondaryPhoneNumber = formValues[SECONDARY_PHONE];
  }

  if (allQuestionsInForm?.[PRIMARY_PHONE]?.name) {
    const phoneFieldName = allQuestionsInForm[PRIMARY_PHONE].name;
    phoneNumber = formValues[phoneFieldName] as string;
  }

  const dynamicDisclaimerText = getMicroPortalDisclaimerText(
    tCPAOnlySchools,
    tCPAOnlySchools.length > 0 ? disclaimerSuccessText : disclaimerText,
    sharedDisclaimerOptInText,
    phoneNumber || '',
    secondaryPhoneNumber || ''
  );

  if (isProcessing) {
    return (
      <div data-testid="disclaimer-loading">
        <Loader text="Searching for schools, please wait..." />
      </div>
    );
  }

  if (tCPAOnlySchools.length === 0) {
    return <div data-testid="disclaimer-no-value" />;
  }

  return (
    <Disclaimer
      disclaimerTextValue={dynamicDisclaimerText}
      onChange={props.onChange}
      value={Boolean(props.value)}
      required={requiresCheckbox}
      name={props.name}
    />
  );
}
