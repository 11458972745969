import { SKIP_REASON_CODES } from 'consts';
import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';
import React, { useContext, useEffect } from 'react';
import { FormValues, Question } from 'types';
import { getFieldValue } from 'utils/formValuesUtils';
import cx from 'utils/classnames';
import { LogError } from 'utils/logging';

interface SkipButtonProps {
  disabled: boolean;
  loading: boolean;
}

function shouldDisplaySkipButton(
  formValues: FormValues,
  currentQuestions: Question[]
): boolean {
  const [currentQuestion] = currentQuestions;

  if (!currentQuestion) {
    return false;
  }

  const formValue = formValues[currentQuestion.name];

  let questionFormObject;

  if (Array.isArray(formValue)) {
    [questionFormObject] = formValue;
  } else {
    questionFormObject = formValue;
  }

  if (typeof questionFormObject === 'string') {
    return false;
  }

  const currentQuestionValue =
    currentQuestion && getFieldValue(formValues, currentQuestion.name);

  const currentQuestionSingleValue = Array.isArray(currentQuestionValue)
    ? currentQuestionValue[0]
    : currentQuestionValue;

  if (
    typeof questionFormObject === 'object' &&
    questionFormObject?.shouldSkip &&
    questionFormObject?.skipReason === SKIP_REASON_CODES.USER_SKIPPED
  ) {
    return true;
  }

  if (currentQuestions?.length !== 1 || currentQuestionSingleValue) {
    return false;
  }

  return typeof currentQuestion.skipValue !== 'undefined';
}

export function SkipButton({
  disabled,
  loading,
}: SkipButtonProps): React.ReactNode {
  const { currentQuestions, handleChange, formValues, handleSubmit } =
    useContext(BasicFormWizardContext);

  const [currentQuestion] = currentQuestions;
  const questionFormObject = formValues[currentQuestion?.name];

  const shouldRenderButton =
    typeof questionFormObject !== 'string' &&
    typeof currentQuestion?.skipValue !== 'undefined';

  const shouldDisplayButton = shouldDisplaySkipButton(
    formValues,
    currentQuestions
  );

  function handleSkipStep(): void {
    if (currentQuestion.skipValue) {
      const valueToSet = {
        value: currentQuestion.skipValue,
        shouldSkip: true,
        skipReason: SKIP_REASON_CODES.USER_SKIPPED,
      };

      handleChange(valueToSet, {
        name: currentQuestion.name,
      });
    } else {
      LogError('SkipButton handleSkipStep: Skip value is not defined');
    }
  }

  useEffect(() => {
    if (shouldDisplayButton) {
      handleSkipStep();
    }
  }, [shouldDisplayButton]);

  if (!shouldRenderButton) {
    return null;
  }

  const classNames = cx({
    basicFormWizard__skipButton: true,
    'basicFormWizard__skipButton--isLoading': loading,
    'basicFormWizard__skipButton--isHidden': !shouldDisplayButton,
  });

  return (
    <button
      type="button"
      data-testid="skip-button"
      onClick={handleSubmit}
      disabled={disabled}
      className={classNames}
      aria-hidden={!shouldDisplayButton}
      tabIndex={shouldDisplayButton ? undefined : -1}
    >
      <span className="basicFormWizard__skipButtonText">Skip</span>
    </button>
  );
}
