/* eslint-disable no-plusplus */
import isEmpty from 'lodash/isEmpty';
import { LogError } from 'utils/logging';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import {
  PROGRAM_GROUP_NAMES,
  parentCategoryFieldIds,
  QUESTION_IDS,
  DEPENDENCIES,
  SKIP_REASON_CODES,
} from 'consts';
import { FIELD_TYPES } from 'components/AVAILABLE_COMPONENTS';
import { getFieldValue, getValidQuestions } from 'utils/formValuesUtils';
import getVisibleQuestions from 'utils/form-utils/getVisibleQuestions';
import derivePreSelections, { getUpdatedValues } from './derivePreSelections';

const { MAIN, RELATED, OTHER } = PROGRAM_GROUP_NAMES;
const { CLEAR_VALUES } = DEPENDENCIES;

export function handleTaxonomyPreSelect(
  dataPoints,
  handleChange,
  setTaxonomyValuesProcessed
) {
  const {
    userData,
    taxonomyValues,
    formStatus,
    allQuestionsInForm,
    microSiteTaxonomyMap,
    formValues,
    currentBreakpoint,
  } = dataPoints;

  // Its possible the current page does not have any taxonomyValues. Currently only Click Portal.
  if (!taxonomyValues) {
    setTaxonomyValuesProcessed();
    return;
  }

  try {
    // given the current page type and the user's selection figure out how the form wizard should update
    const derivedFormValue = derivePreSelections({
      userData,
      taxonomyValues,
      allQuestionsInForm,
      currentBreakpoint,
    });

    // If there should be changes process them
    if (derivedFormValue.length && !formStatus.isInitializing) {
      const updatedValues = getUpdatedValues(
        derivedFormValue,
        allQuestionsInForm,
        microSiteTaxonomyMap,
        formValues
      );

      updatedValues.forEach(({ updatedValue, fieldName }) =>
        handleChange(updatedValue, { name: fieldName, isNonUserAction: true })
      );

      setTaxonomyValuesProcessed();
    } else if (!derivedFormValue.length) {
      setTaxonomyValuesProcessed();
    }
  } catch (error) {
    LogError(`handleTaxonomyPreSelect failed due to ${error.message}`);
    setTaxonomyValuesProcessed();
  }
}

/**
 * @summary given the current state of the form find out if the form is eligible to be resumed
 * @param {Object} formState
 */
export function getIsFormResumeEligible(state) {
  const { isFormResumeEligible } = state.formStatus;

  let _isFormResumeEligible = isFormResumeEligible;

  try {
    if (!isFormResumeEligible) {
      const [questionId] = parentCategoryFieldIds;
      const subjectQuestion = get(
        state,
        `allQuestionsInForm[${questionId}]`,
        {}
      );

      const isSubjectSkipped = get(
        state,
        `formValues[${subjectQuestion.name}].shouldSkip`
      );

      // only Eligible if subject was answered and form is dirty
      _isFormResumeEligible =
        isSubjectSkipped ||
        state.currentQuestions.some(
          ({ name }) => name === subjectQuestion.name
        );
    }
  } catch (error) {
    LogError('Failed To Figure Out If we Can Pop the Resume Modal', {
      errorMessage: error.message,
    });
  }

  return _isFormResumeEligible;
}

/**
 * @summary we need to sort program by:
 * - first by group [main, related, other].
 * - then need to sort by flags [featured, expanded].
 * - then by an orderBy attribute either wordpress or triadms [wpOrderBy, orderBy]
 */
export function sortByGroup(programs, sortKey, groupName) {
  const group = groupName
    ? filter(programs, ['groupType', groupName])
    : programs.filter(({ groupType }) => !groupType);

  const featuredProgram = [];
  const expandedProgram = [];
  const otherProgram = [];

  group.forEach((program) => {
    if (program.isFeatured) {
      featuredProgram.push(program);
    } else if (program.isExpanded) {
      expandedProgram.push(program);
    } else {
      otherProgram.push(program);
    }
  });

  const sortedPrograms = [].concat(
    orderBy(featuredProgram, [sortKey], ['asc']),
    orderBy(expandedProgram, [sortKey], ['asc']),
    orderBy(otherProgram, [sortKey], ['asc'])
  );

  return sortedPrograms;
}

/**
 * @summary helper function that will sort by current 3 groups we have
 */
export function sortPrograms(programs, isWordpressOrder) {
  // on page load, or when no selection, we want to sort by how wordpress tells us via drag and drop UI
  // one thing to note is that only the initial values will have this wpSort set, as the global programs list can not.
  // see https://triadms.atlassian.net/browse/T1-2855 for more info on how sorting should work
  const sortKey = isWordpressOrder ? 'wpOrderBy' : 'orderBy';

  const sortedPrograms = [].concat(
    sortByGroup(programs, sortKey, MAIN),
    sortByGroup(programs, sortKey, RELATED),
    sortByGroup(programs, sortKey, OTHER),
    sortByGroup(programs, sortKey) // all other un-grouped
  );

  return sortedPrograms;
}

/**
 * @summary this function is used to get all question a user will be able to see on any given step
 * @param {Object} step - A step (aka substep) in the form
 * @param {Object} state current state of the form
 */
export function getValidAndVisibleQuestions(step, state) {
  // we need to get all valid question for a step, checks dependencies
  const nextStepQuestions = getValidQuestions(
    step.questions,
    state.formValues,
    state.allQuestionsInForm,
    state.formStatus.dynamicOptions
  );

  // its possible a question passes dependencies but some external factor wants us to hide it, such as user program module selection
  return getVisibleQuestions(nextStepQuestions, state);
}

/**
 * @summary This is used to calculate the percent of the form
 * @param {Object} state current state of the form
 */
export function generatePercentComplete(formState) {
  const { formConfigs, currentStepIndex } = formState;
  try {
    if (!get(formConfigs, 'steps', []).length) {
      return 0;
    }

    if (formState.isThankyouPage) {
      return 100;
    }
    const currentStep = formConfigs.steps[currentStepIndex];
    const [startProg, endProg] = currentStep.progressMeterRange.split('-');
    const startProgInt = parseInt(startProg, 10);
    const endProgInt = parseInt(endProg || startProg, 10);

    const allValidSubsteps = formConfigs.steps.filter((step) => {
      if (step.trueStepIndex !== currentStep.trueStepIndex) {
        return false;
      }

      return true;
    });

    const lastSubStep = allValidSubsteps[allValidSubsteps.length - 1];

    // it could be that there is only one question on a step but it has been skipped
    if (!allValidSubsteps.length) {
      return endProgInt;
    }

    if (allValidSubsteps[0].subStepIndex === currentStep.subStepIndex) {
      return startProgInt;
    }

    if (currentStep.subStepIndex === lastSubStep.subStepIndex) {
      return endProgInt;
    }

    const groupProgDiff =
      (endProgInt - startProgInt) / (allValidSubsteps.length - 1);

    const activeCurrentStepIndex = allValidSubsteps.findIndex(
      ({ subStepIndex }) => subStepIndex === currentStep.subStepIndex
    );

    return parseInt(startProgInt + groupProgDiff * activeCurrentStepIndex, 10);
  } catch (error) {
    LogError('Failed To Generate Percent Bar', {
      currentStepIndex,
      stepsCount: get(formConfigs, 'steps', []).length,
      error: error.message,
    });
  }

  return 0;
}

/**
 * @summary Its possible a user has selected a form value that needs to set a value on another field. its also possible that the user loads the page with session values that should hide other fields based on their values. (T1-3985)
 */
let didTrackPreFillError = false;
// update to go to step that was cleared
export function applyPreFills(formBag, currentFormValues, updatedFormValues) {
  const latestUpdate = {
    ...currentFormValues,
    ...(updatedFormValues || {}),
  };

  // note that we don't default updatedFormValues because its absence means we don't want to update from values, but addd the metadata from the preFillAnswer
  const affectedFormValues = updatedFormValues || currentFormValues;

  try {
    // set any isPreFilled to false as to know confuse us thinking all isPreFilled happened in single run
    Object.keys(latestUpdate).forEach((fieldName) => {
      if (latestUpdate[fieldName]?.isPreFilled) {
        latestUpdate[fieldName].isPreFilled = false;
      }
    });

    // get the ids and names of all field values that can have caused a changed
    const fieldNamesUpdated = Object.keys(affectedFormValues);
    const fieldIdsUpdated = fieldNamesUpdated
      .map((fieldName) => ({
        id: get(formBag, `fieldNameMap[${fieldName}].id`),
        name: fieldName,
      }))
      .filter(({ id }) => id);

    // for each field see if its value has a preFill option
    fieldIdsUpdated.forEach(({ id, name }) => {
      // gather all options and dynamic options
      const options = get(formBag, `allQuestionsInForm[${id}].options`, []);
      const dynamicOptions = get(
        formBag,
        `formStatus.dynamicOptions[${id}].options`,
        []
      );

      // dynamic options take priority
      const appliedOptions = dynamicOptions.length ? dynamicOptions : options;

      // find the option that is selected
      const selectedValue = getFieldValue(affectedFormValues, name);
      const selectedOption = appliedOptions.find(({ value, guid }) =>
        [value, guid].includes(selectedValue)
      );

      // if an option is selected and it has a prefillAnswers take action
      if (selectedOption && selectedOption.prefillAnswers) {
        // for each prefillAnswers we may need to update the form values
        selectedOption.prefillAnswers.forEach((preSelection) => {
          const questionName =
            formBag.allQuestionsInForm[preSelection.questionId].name;
          // if there is no updatedFormValues we assume the session ran this on all form values
          if (!updatedFormValues) {
            const currentValueOfPrefill = getFieldValue(
              currentFormValues,
              questionName
            );
            // we ONLY update a form value if its the same as some prefillAnswers of another field
            // this is mainly when we load the page and get the user's session. we dont want to overwrite values but want to add the shouldShow attribute
            if (currentValueOfPrefill === preSelection.value) {
              latestUpdate[questionName] = {
                ...preSelection,
                isPreFilled: true,
              };
            }
          } else {
            // if this was trigger for a form value update then update the preFill question
            latestUpdate[questionName] = { ...preSelection, isPreFilled: true };
          }
        });
      }
    });
  } catch (error) {
    if (!didTrackPreFillError) {
      LogError('Failed To Prefill Form State', { message: error.message });
      didTrackPreFillError = true;
    }
  }

  // Handle the CLEAR_VALUES dependance to clear other field values
  const questionsCleared = [];
  if (!isEmpty(updatedFormValues)) {
    Object.keys(updatedFormValues).forEach((fieldName) => {
      const newValue = getFieldValue(updatedFormValues, fieldName);
      const currentValue = getFieldValue(currentFormValues, fieldName);

      if (newValue !== currentValue) {
        const { dependency } = get(formBag, `fieldNameMap[${fieldName}]`, {});
        const dependents = get(dependency, `[${CLEAR_VALUES}].questionIds`, []);

        dependents.forEach((questionId) => {
          const question = get(
            formBag,
            `allQuestionsInForm[${questionId}]`,
            {}
          );
          if (latestUpdate[question.name]) {
            latestUpdate[question.name] = undefined;
            questionsCleared.push(question);
          }
        });
      }
    });
  }

  return { updatedForm: latestUpdate, questionsCleared };
}

/**
 * @summary use this to find out if the next question the user will see has a DYNAMIC_VISIBILITY dependency
 * @param {Object} formState - the formState that useBasicFormWizardHandler produces
 * @param {Number} currentStepIndex - the current step you are on
 */
export function isNextStepDynamic(formState, currentStepIndex) {
  const { formConfigs } = formState;

  let nextStepQuestions;

  for (let i = currentStepIndex + 1; i < formConfigs.steps.length; i++) {
    const nextQuestions = get(formConfigs, `steps[${i}].questions`, []);

    if (getVisibleQuestions(nextQuestions, formState).length) {
      nextStepQuestions = nextQuestions;
      break;
    }
  }

  if (!nextStepQuestions) {
    return false;
  }

  return nextStepQuestions.some((question) =>
    get(question, 'dependency.DYNAMIC_VISIBILITY')
  );
}

export function setValueIfOnlyOneOption(
  formValues,
  dynamicOptions,
  allQuestionsInForm
) {
  try {
    const _formValues = { ...formValues };

    Object.values(dynamicOptions).forEach((question) => {
      if (!question.options) {
        return;
      }

      const { name } = allQuestionsInForm[question.questionId];
      const isASkippedQuestion = _formValues[name]?.shouldSkip;
      const skippedReason = _formValues[name]?.skipReason;
      const isTaxonomySkippedConcentrationsQuestion =
        isASkippedQuestion &&
        skippedReason === SKIP_REASON_CODES.TAXONOMY &&
        question.questionId === QUESTION_IDS.CONCENTRATIONS;

      // If this question was skipped due to user interaction do nothing
      if (
        isASkippedQuestion &&
        skippedReason !== SKIP_REASON_CODES.SINGLE_VALUE &&
        !isTaxonomySkippedConcentrationsQuestion
      ) {
        !skippedReason &&
          LogError('A skipped question does not have a reason code.', {
            name,
            questionId: question.questionId,
          });
        return;
      }

      // note that its possible current value is an array
      const currentValue = getFieldValue(_formValues, name);

      // handle grouped options as well
      let currentOptions = question.options
        .reduce((acc, group) => {
          acc.push(group.options || group);
          return acc;
        }, [])
        .flat();

      const isObsoleteOption = !currentOptions.find(({ value, guid }) => {
        if (Array.isArray(currentValue)) {
          return currentValue.find((selection) =>
            [value, guid].includes(selection)
          );
        }

        return [value, guid].includes(currentValue);
      });

      // if current question is Concentrations strip out the UNDECIDED option
      // we only care about actual concentrations when we decide to preselect
      if (question.questionId === QUESTION_IDS.CONCENTRATIONS) {
        currentOptions = currentOptions.filter(
          ({ guid }) => guid !== '00000000-0000-0000-0000-000000000000'
        );
      }

      if (
        currentOptions.length === 1 &&
        question.questionId !== QUESTION_IDS.MICRO_PORTAL_DYNAMIC_TCPA &&
        question.questionId !==
          QUESTION_IDS.MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION
      ) {
        _formValues[name] = {
          ...currentOptions[0],
          shouldSkip: true,
          skipReason: SKIP_REASON_CODES.SINGLE_VALUE,
        };
      } else if (skippedReason === SKIP_REASON_CODES.TAXONOMY) {
        // do nothing - due to recent changes concentrations can get this far, but we do not want to mess with it unless it is a single value
      } else if (isObsoleteOption || currentOptions.length === 0) {
        delete _formValues[name];
      } else if (currentOptions.length > 1 && isASkippedQuestion) {
        _formValues[name].shouldSkip = false;
      }
    });
    return _formValues;
  } catch (error) {
    LogError(`setValueIfOnlyOneOption: failed due to ${error.message}`, {
      allQuestionsInForm,
      dynamicOptions,
    });
    return formValues;
  }
}

/**
 * @summary This is used to apply the correct sort and bucket to programs that don't come from WP but need to respect the pages taxonomy
 * @see https://triadms.atlassian.net/browse/T1-4762
 */
export function applyWpOrderBy(updatedPrograms, pageTaxonomy = {}) {
  // run over updatedPrograms to apply logic to set wpOrderBy to orderBy if isDefaultPageSort and program does not have a wpOrderBy
  // logic should add same Category programs to groupType of MAIN, update its wpOrderBy to this.orderBy + Main[Main.length - 1].wpOrderBy
  // logic should add same Parent Category programs to groupType of RELATED, update its wpOrderBy to this.orderBy + RELATED[RELATED.length - 1].wpOrderBy

  const reUpdatedProgram = [];

  // check that the page has taxonomy values
  const catGuid = get(pageTaxonomy, 'categories[0]');
  const parGuid = get(pageTaxonomy, 'parentCategories[0]');

  // no changed needed if default landing
  if (!catGuid && !parGuid) {
    return updatedPrograms;
  }

  const maxOrder = Math.max(
    ...updatedPrograms.map(({ wpOrderBy }) => wpOrderBy || 0)
  );

  // if this page has taxonomy values we may need to update program into a group and its order
  for (let i = 0; i < updatedPrograms.length; i++) {
    const program = { ...updatedPrograms[i] };

    if (program.isGlobalList) {
      if (program.category.includes(catGuid)) {
        program.wpOrderBy = program.orderBy + maxOrder;
        program.groupType = MAIN;
        program.isOrderModified = true;
      } else if (program.parentCategory.includes(parGuid)) {
        program.wpOrderBy = program.orderBy + maxOrder;
        program.groupType = RELATED;
        program.isOrderModified = true;
      }
    }

    reUpdatedProgram.push(program);
  }

  return reUpdatedProgram;
}

export function getTransitionInStatus(formStatus, isFirstStep) {
  let isVisible = false;

  if (!formStatus.isInitializing) {
    isVisible = true;
  }

  if (!formStatus.isDirty || !isFirstStep) {
    isVisible = true;
  }

  return isVisible;
}

/**
 * @summary This is used to know if the user can resume the form. It takes into account if the user has session values from other micro sites
 * @param {Object} state - state of the form
 * @param {Object} sessionValues - current site session values
 * @param {Object} linkedSessionValues - object we create with status of session values and if they are from other sites
 * @returns
 */
export function canResumeForm(state, sessionValues, linkedSessionValues) {
  const isFormResumeEligible = getIsFormResumeEligible(state);
  let hasValidSessionValues = !!sessionValues;

  if (hasValidSessionValues) {
    // if there are session values make sure that they are not from another site
    hasValidSessionValues = !!filter(
      linkedSessionValues,
      (v) => v.status !== 'skip'
    ).length;
  }

  return isFormResumeEligible && hasValidSessionValues;
}

// TODO: [T1-11709] Write unit tests for this function
export function isDynamicDisclaimerStep(questions) {
  if (!questions) {
    return false;
  }

  return questions.some(
    (question) => question.type === FIELD_TYPES.DYNAMIC_DISCLAIMER
  );
}
