import React from 'react';

export default function FormLoader(): React.JSX.Element {
  return (
    <div className="formLoader">
      <div className="formLoader__textLine" />
      <div className="formLoader__inputLine" />
      <div className="formLoader__buttonLine" />
    </div>
  );
}
