import React from 'react';
import { BasicFormWizardContext as ContextType } from 'types';
import { initialState } from 'hooks/custom/forms/basic-wizard/reducer';

const basicFormWizardContextInitialState: ContextType = {
  allQuestionsInForm: null,
  currentStepIndex: initialState.currentStepIndex,
  formValues: {},
  formValuesV2: {},
  formStatus: {
    isProcessing: initialState.formStatus.isProcessing,
    isSubmitting: initialState.formStatus.isSubmitting,
    dynamicOptions: {},
    stepsValidationStatus: [],
  },
  handleChange: (__fieldValue, __event) =>
    Promise.reject(new Error('Not implemented')),
  formConfigs: {
    hasDynamicDisclaimer: false,
    schoolCode: '',
    variant: '',
    id: 0,
    stepsCount: 0,
    steps: [],
    useLandingPageHeadline: false,
    isFullPage: false,
    disableLogging: false,
    initialStepIndex: 0,
  },
  fieldNameMap: {},
  handleSubmit: () => {},
  currentQuestions: [],
  actions: {
    setStatus: () => {},
  },
  isLastStep: false,
};

const BasicFormWizardContext = React.createContext<ContextType>(
  basicFormWizardContextInitialState
);

export default BasicFormWizardContext;
