/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'utils/classnames';
import GlobalContext from 'hooks/contexts/GlobalContext';
import FormStep from 'components/form-wizards/basic-form/FormStep';
import DefaultErrorBoundary from 'components/error-boundaries/DefaultErrorBoundary';
import StepsWithSelections from 'components/base/steps/StepsWithSelections';
import { trackQuestionAnswersOnStep } from 'utils/trackingFunctions';
import ClickPortalFormContainer from './ClickPortalFormContainer';
import useMultiFormStateSync from './utils/useMultiFormStateSync';

function Form(props) {
  const {
    currentStepIndex,
    currentQuestions,
    isFirstStep,
    formStatus,
    steps,
    showSteps,
    title,
    formValues,
  } = props;

  useMultiFormStateSync(props);

  const {
    refs: { clickPortalSearchResultsRef },
  } = useContext(GlobalContext);

  const stepContainerClass = cx({
    basicFormWizard__stepContainer: true,
  });

  // on page load the user might have pre-set values due to filter key or query params
  useEffect(() => {
    if (formStatus.isFormReadyForViewing) {
      trackQuestionAnswersOnStep(currentQuestions, formValues);
    }
  }, [formStatus.isFormReadyForViewing]);

  // normally this is tracked on moveToNextStep but since this form only has one step its never tracked
  useEffect(() => {
    if (formStatus.isSubmitting) {
      trackQuestionAnswersOnStep(currentQuestions, formValues);
    }
  }, [formStatus.isSubmitting]);

  return (
    <div
      className={
        isFirstStep
          ? 'basicFormWizard basicFormWizard--isClickPortal basicFormWizard--isFirstStep'
          : 'basicFormWizard basicFormWizard--isClickPortal'
      }
    >
      {showSteps && clickPortalSearchResultsRef && (
        <div className="basicFormWizard__head">
          <StepsWithSelections />
        </div>
      )}
      <div className="basicFormWizard__container">
        <h1 className="basicFormWizard__title">{title}</h1>
        <div className={stepContainerClass}>
          <DefaultErrorBoundary
            meta={{ currentStepIndex }}
            key={currentStepIndex}
          >
            <FormStep
              questions={currentQuestions}
              stepLabel={steps[currentStepIndex]?.groupLabel}
              stepIndex={currentStepIndex}
              formIsDirty={formStatus.isDirty}
            />
          </DefaultErrorBoundary>
        </div>
      </div>
    </div>
  );
}

export default function ClickPortalSidebarForm(props) {
  return <ClickPortalFormContainer Form={Form} {...props} />;
}

ClickPortalSidebarForm.propTypes = {
  formOptions: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
};
