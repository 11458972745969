import {
  categoryFieldIds,
  parentCategoryFieldIds,
  programFieldIds,
  QUESTION_IDS,
  SKIP_REASON_CODES,
} from 'consts';
import get from 'lodash/get';
import { LogError } from 'utils/logging';

/**
 * @summary this function is used to figure out what questions to hide/show or preselect based off of user data
 * @param {Object} userData.parentCategorySelection - the selected area of interest option the user selected
 * @param {Object} userData.categorySelection - the selected program the user selected to learn more about
 * @param {Array} taxonomyValues.degrees - the degrees associated with the current page
 * @param {Array} taxonomyValues.parentCategories - the parentCategories associated with the current page
 * @param {Array} taxonomyValues.categories - the categories associated with the current page
 */
export default function derivePreSelections({
  userData,
  taxonomyValues,
  allQuestionsInForm = {},
  currentBreakpoint,
}) {
  const { degrees, parentCategories, categories, programs } = taxonomyValues;
  const {
    parentCategorySelection, // 'What subjects are you interested in?'
    programOfInterest,
  } = userData;

  const shouldSkipConcentrationWhenPossible =
    !allQuestionsInForm[QUESTION_IDS.CONCENTRATIONS]?.additionalProperties?.[
      currentBreakpoint
    ]?.shouldNotSkip?.shouldNotSkip;
  const isDegreeLanding = !!degrees.length;
  const isParentCatLanding = !!parentCategories.length;
  const isCategoryLanding = !!categories.length;
  const isProgramLanding = !!programs.length;
  const isDefaultLanding =
    !isDegreeLanding && !isParentCatLanding && !isCategoryLanding;

  const parentCategory = {
    type: 'parentCategories',
    isparentCategory: true,
    questionIds: parentCategoryFieldIds,
    value:
      get(programOfInterest, 'parentCategory[0]') || // strip from program selection
      get(parentCategorySelection, 'value') || // user selection
      get(parentCategories, '[0]'), // page level
    skip: false,
  };

  const category = {
    type: 'categories',
    isCategory: true,
    questionIds: categoryFieldIds,
    value:
      get(programOfInterest, 'category[0]') || // user selection
      get(categories, '[0]'), // strip from program selection,
    skip: false,
  };

  const program = {
    type: 'programs',
    isProgram: true,
    questionIds: programFieldIds,
    label: get(programOfInterest, 'title'),
    value: get(programOfInterest, 'guid') || get(programs, '[0]'), // user selection or T1-5872, program in taxonomy
    skip: false,
  };

  const resetCategory = { ...category, value: null };
  const resetParentCat = { ...parentCategory, value: null };
  const resetProgram = { ...program, value: null };

  const skipCategory = {
    ...category,
    skip: shouldSkipConcentrationWhenPossible,
    skipReason: programOfInterest
      ? SKIP_REASON_CODES.PROGRAM_SELECTION
      : SKIP_REASON_CODES.TAXONOMY,
  };
  const skipParentCat = {
    ...parentCategory,
    skip: true,
    skipReason: programOfInterest
      ? SKIP_REASON_CODES.PROGRAM_SELECTION
      : SKIP_REASON_CODES.TAXONOMY,
  };

  /* We know exactly what the user wants */
  if (programOfInterest) {
    if (!skipParentCat.value || !skipCategory.value) {
      LogError(
        'programOfInterest selected but no category or parent category value found',
        { programOfInterest, skipParentCat, skipCategory }
      );
    }
    return [skipParentCat, skipCategory, program];
  }

  /* Program Landing */
  if (isProgramLanding) {
    if (parentCategorySelection) {
      return [skipParentCat, resetCategory, resetProgram];
    }

    if (skipParentCat.value && skipCategory.value) {
      return [skipParentCat, skipCategory, program];
    }

    return [];
  }

  /* Generic Landing */
  if (isDefaultLanding) {
    if (parentCategorySelection) {
      return [parentCategory];
    }

    if (get(userData, 'sessionFormValues.Subject')) {
      return [];
    }

    return [resetParentCat];
  }

  /* Category Landing */
  if (isCategoryLanding) {
    if (!parentCategorySelection) {
      return [skipParentCat, skipCategory];
    }

    if (
      get(parentCategorySelection, 'value') === get(parentCategories, '[0]')
    ) {
      return [parentCategory, resetCategory];
    }

    return [resetParentCat, resetCategory];
  }

  /* Parent Category Landing */
  if (isParentCatLanding) {
    const p = parentCategorySelection;
    if (!p || get(p, 'value') === get(parentCategories, '[0]')) {
      return [skipParentCat];
    }

    return [resetParentCat];
  }

  /* degree pages */
  if (isDegreeLanding) {
    if (parentCategorySelection) {
      return [parentCategory];
    }
  }

  return [];
}

/**
 * @summary Not really reusable, extracted this out just to unit test easier
 * @param {Array} derivedFormValue - return value of derivePreSelections
 * @param {Object} allQuestionsInForm - map of form questions
 * @param {Object} microSiteTaxonomyMap - map of site taxonomy
 */
export function getUpdatedValues(
  derivedFormValue,
  allQuestionsInForm,
  microSiteTaxonomyMap
) {
  // for each change update the formValues
  return derivedFormValue.map((derivedValue) => {
    // only support 1 question update for a single taxonomy
    const [questionId] = derivedValue.questionIds;
    const fieldName = get(allQuestionsInForm, `[${questionId}].name`);

    // we know the taxonomy id but we need to fetch its title for the dropdown
    const taxonomyValue = get(
      microSiteTaxonomyMap,
      `${derivedValue.type}[${derivedValue.value}]`
    );

    // we should always have found one, if so update the form.
    if (taxonomyValue) {
      let updatedValue = {
        value: taxonomyValue.guid,
        label: taxonomyValue.title,
        shouldSkip: derivedValue.skip,
        skipReason: derivedValue.skipReason,
      };

      // categories, aka concentrations, are an array of values
      updatedValue = derivedValue.isCategory ? [updatedValue] : updatedValue;

      return {
        updatedValue,
        fieldName,
      };
    }

    // Note that for a program selection we will have all info already in derivedValue
    if (derivedValue.isProgram) {
      return {
        updatedValue: {
          value: derivedValue.value,
          label: derivedValue.label,
          shouldSkip: derivedValue.skip,
          skipReason: derivedValue.skipReason,
        },
        fieldName,
      };
    }

    if (derivedValue.value) {
      LogError('Unable to Update Form Values:', { derivedValue });
    }

    return {
      updatedValue: '',
      fieldName,
    };
  });
}
