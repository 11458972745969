import { QUESTION_IDS } from 'consts';
import { tokenReplacement } from 'hooks/custom/useTokenReplacement';
import { FormStatus, School, SchoolImpressionGuid } from 'types';

const { MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION } = QUESTION_IDS;
const GTS_AND_CTS_SCHOOL_ID = 'GTS_AND_CTS_SCHOOL_ID';

export function getTCPAOnlyListingOptions(
  dynamicOptions: FormStatus['dynamicOptions']
): School[] {
  const schoolOptions = (dynamicOptions[MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION]
    ?.options || []) as School[];

  return schoolOptions
    .filter((option) => option.submissionType === 'MicroportalTCPAOnlySchool')
    .sort((a, b) => (a.orderBy ?? 0) - (b.orderBy ?? 0));
}

export function getSchoolCardsListingOptions(
  dynamicOptions: FormStatus['dynamicOptions']
): School[] {
  const schoolOptions = (dynamicOptions[MICRO_PORTAL_PRIMARY_SCHOOLS_SELECTION]
    ?.options || []) as School[];

  return schoolOptions
    .filter((option) => option.submissionType === 'MicroportalSchoolCards')
    .sort((a, b) => (a.orderBy ?? 0) - (b.orderBy ?? 0));
}

export function getMicroPortalDisclaimerText(
  schools: School[],
  defaultDisclaimerText: string,
  defaultOptInDisclaimerText: string,
  phoneNumber: string,
  secondaryPhoneNumber: string
): string {
  let _disclaimerText = defaultDisclaimerText;
  const requiresOptIn = schools.some((school) => school.hasTcpaCheckbox);

  if (schools.length === 1 && schools[0]?.terms) {
    _disclaimerText = schools[0].terms;
  } else if (requiresOptIn) {
    _disclaimerText = defaultOptInDisclaimerText;
  }

  const trustedFormTag = (schoolName: string): string =>
    `<span data-tf-element-role="consent-advertiser-name">${schoolName}</span>`;

  let formattedSchoolNames = '';
  const taggedSchoolNames = schools.map((school) =>
    trustedFormTag(school.label)
  );

  if (taggedSchoolNames.length === 1) {
    // eslint-disable-next-line prefer-destructuring
    formattedSchoolNames = taggedSchoolNames[0];
  } else if (schools.length === 2) {
    formattedSchoolNames = `${taggedSchoolNames[0]} and ${taggedSchoolNames[1]}`;
  } else {
    formattedSchoolNames = taggedSchoolNames.reduce(
      (acc, schoolName, index) => {
        if (index === 0) return schoolName;

        if (index === schools.length - 1) return `${acc}, and ${schoolName}`;

        return `${acc}, ${schoolName}`;
      },
      ''
    );
  }

  return tokenReplacement(
    _disclaimerText,
    {
      '{SELECTED_SCHOOL_NAME}': formattedSchoolNames,
    },
    { phoneNumber, secondaryPhoneNumber }
  );
}

export function getGTSAndCTSSchoolObject(): School {
  return {
    submissionType: 'MicroportalTCPAOnlySchool',
    orderBy: 0,
    degreeList: [],
    description: '',
    featuredImage: { mobile: '', desktop: '' },
    highlights: '',
    id: GTS_AND_CTS_SCHOOL_ID,
    label: 'GuidetoSchools and CompareTopSchools',
    matchingProgramOptions: [],
    originalSelectedProgram: { value: '', label: '' },
    rating: 0,
    schoolLogo: { mobile: '', desktop: '' },
    terms: '',
    impressionGuid: '' as SchoolImpressionGuid,
    impressionGroupGuid: '',
    hasTcpaCheckbox: false,
    submitText: '',
  };
}

export function isGTSAndCTSSchool(school: School): boolean {
  return school.id === GTS_AND_CTS_SCHOOL_ID;
}
