/**
 * TODO: [T1-9336] write end to end test for this form once in production
 * This is the main form for MicroPortal
 */
import React from 'react';
import BasicFormWizard from '../basic-form/BasicFormWizard';
import useMicroPortalFormHandler from './useMicroPortalFormHandler';

/**
 * The Form component that is feed into the FormContainer.
 * This Form's props will be all form utilities and form values.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function MicroPortalForm(props: any): React.JSX.Element {
  const { onLogProgress, onOptionsRequest, onFormSubmit, onMoveToNextStep } =
    useMicroPortalFormHandler();

  const buttonText = {
    first: 'Continue',
    last: 'Submit',
    disclaimer: 'Request Info',
  };

  return (
    <BasicFormWizard
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      disableSideEffects
      customSubmitHandler={onFormSubmit}
      onMoveToNextStep={onMoveToNextStep}
      onOptionsRequest={onOptionsRequest}
      onLogProgress={onLogProgress}
      className="isMicroPortal"
      buttonText={buttonText}
    />
  );
}
