import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classNames from 'utils/classnames';
import CardCTA from 'components/blocks/cards/expandable-program-card/CardCTA';
import { getDayOfWeekName, getMonthName } from 'utils/startDateHelpers';
import { getCurrentDateFromArray } from './utils';

function AdmissionsDates({ startDates, label }) {
  const admissionDatesClasses = classNames({
    admissionsDates: true,
    [`admissionsDates--${Object.keys(startDates).length}up`]: true,
  });

  const memoizedDates = useMemo(() => {
    return Object.keys(startDates)
      .map((dateType) => {
        const currentDate = getCurrentDateFromArray(
          get(startDates, `${dateType}.startDates`, [])
        );

        return { dateType, label: startDates[dateType].label, currentDate };
      })
      .filter((entry) => entry.currentDate); // Filtering to include only entries with a currentDate
  }, [startDates]);

  return (
    <div className={admissionDatesClasses} data-testid="admissionsDates">
      {label && memoizedDates.length > 0 && (
        <div className="admissionsDates__datesLabel">
          {label}
          <div className="admissionsDates__datesLabelDisclaimer">
            (Other start dates available)
          </div>
        </div>
      )}
      <ul
        className="admissionsDates__dates"
        data-testid="admissionsDatesContainer"
      >
        {memoizedDates.map((currentStartDate, index) => {
          if (!currentStartDate.currentDate) {
            return null;
          }
          return (
            <li
              className="admissionsDates__date"
              data-testid={`admissionsDates__date_${index}`}
              key={currentStartDate.dateType}
            >
              <div className="admissionsDates__header">
                <div
                  className="admissionsDates__title"
                  data-testid={`admissionsDates__title_${index}`}
                  suppressHydrationWarning
                >
                  {currentStartDate.label}
                </div>
              </div>
              <div className="admissionsDates__body">
                <div className="admissionsDates__label">Next Start Date</div>
                <div
                  className="admissionsDates__dayOfWeek"
                  data-testid={`admissionsDates__dayOfWeek_${index}`}
                  suppressHydrationWarning
                >
                  {getDayOfWeekName(currentStartDate.currentDate)}
                </div>
                <div
                  className="admissionsDates__month"
                  data-testid={`admissionsDates__month_${index}`}
                  suppressHydrationWarning
                >
                  {getMonthName(currentStartDate.currentDate)}
                </div>
                <div className="admissionsDates__day" suppressHydrationWarning>
                  {currentStartDate.currentDate.getUTCDate()}
                </div>
              </div>
              <div className="admissionsDates__footer">
                <CardCTA
                  callToActionBehavior="fullPageForm"
                  className="admissionsDates__button"
                >
                  Request Info
                </CardCTA>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

AdmissionsDates.propTypes = {
  label: PropTypes.string,
  startDates: PropTypes.objectOf(
    PropTypes.shape({
      degreeeType: PropTypes.string,
      label: PropTypes.string,
      startDates: PropTypes.arrayOf(
        PropTypes.shape({
          registerBy: PropTypes.string,
          startDate: PropTypes.string,
          startDateInMilliseconds: PropTypes.number,
        })
      ),
    })
  ),
};

export default AdmissionsDates;
