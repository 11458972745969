/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode } from 'react';
import cx from 'utils/classnames';

interface CarouselSlideProps {
  children: ReactNode;
  isActive?: boolean;
  position?: 'prev' | 'next' | 'inactive';
  relativePath?: 'beforeActive' | 'afterActive';
  relativeIndex: number;
  [key: string]: unknown;
}

function CarouselSlide({
  children,
  isActive,
  position,
  relativePath,
  relativeIndex,
  ...props
}: CarouselSlideProps): React.ReactElement {
  const carouselSlideClass = cx('carouselSlide', {
    'carouselSlide--isActive': isActive,
    [`carouselSlide--${position}`]: position,
    [`carouselSlide--${relativePath}`]: relativePath,
    [`carouselSlide--relativeIndex${relativeIndex}`]: relativeIndex,
  });

  return (
    <li className={carouselSlideClass} {...props}>
      {children}
    </li>
  );
}

export default CarouselSlide;
