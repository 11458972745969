/* TODO: remove these eslint-disable rules */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useRef, useState, useEffect } from 'react';
import cx from 'utils/classnames';
import { CSSTransition } from 'react-transition-group';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import MultiSchoolSelectContext from 'components/base/inputs/multiSchoolSelect/multiSchoolSelectContext';

import { UseMultiSchoolSelect } from 'types';
import useWindowSize from 'hooks/custom/useWindowSize';
import useCSSsettings from 'hooks/custom/forms/useCSSsettings';
import SchoolLogo from '../SchoolLogo';

import { SelectionCardExpanded } from './SelectionCardExpanded';
import { SelectionCardActions } from './SelectionCardActions';

interface SelectionCardProps {
  id: string;
  isListTransitioning: boolean;
  onTransitionEnd: () => void;
  onTransitionStart: () => void;
  index: number;
}

export function SelectionCard(props: SelectionCardProps): React.JSX.Element {
  const { id, isListTransitioning, onTransitionEnd, onTransitionStart, index } =
    props;

  const selectionCardRef = useRef<HTMLDivElement>(null);
  const expandedContentRef = useRef<HTMLDivElement>(null);

  const [cardStyles, setCardStyles] = useState({});
  const { state, handleSelectSchool } = useContext<UseMultiSchoolSelect>(
    MultiSchoolSelectContext
  );
  const { formSpeed } = useCSSsettings(selectionCardRef);
  const { width, currentBreakpoint } = useWindowSize();
  const {
    isExpanded,
    isSkipped,
    isSubmitted,
    isAccepted,
    isShowingDetails,
    selectedProgram,
    schoolInfo: { schoolLogo, featuredImage, label, submitText },
  } = state[id];

  const termsRef = useRef<HTMLDivElement>(null);

  function updateInitialCardY(): void {
    if (selectionCardRef.current) {
      const rect = selectionCardRef.current.getBoundingClientRect();

      setCardStyles((prev) => ({
        ...prev,
        '--initialCardY': `${rect.top}px`,
      }));
    }
  }

  function setInitialCardMeasurements(): void {
    requestAnimationFrame(() => {
      if (selectionCardRef.current) {
        const card = selectionCardRef.current;
        const rect = card.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        setCardStyles({
          '--viewportWidth': `${viewportWidth}px`,
          '--viewportHeight': `${viewportHeight}px`,
          '--initialCardWidth': `${rect.width}px`,
          '--initialCardHeight': `${rect.height}px`,
          '--initialCardX': `${rect.left}px`,
          '--initialCardY': `${rect.top}px`,
        });
      }
    });
  }

  function setExpandedCardMeasurements(): void {
    requestAnimationFrame(() => {
      if (selectionCardRef.current) {
        const card = selectionCardRef.current;
        const rect = card.getBoundingClientRect();
        const termsRect = termsRef.current?.getBoundingClientRect();

        setCardStyles((prev) => ({
          ...prev,
          '--expandedCardWidth': `${rect.width}px`,
          '--expandedCardHeight': `${rect.height}px`,
          '--expandedCardX': `${rect.left}px`,
          '--expandedCardY': `${rect.top}px`,
          '--termsHeight': termsRect ? `${termsRect.height}px` : '0px',
          '--termsWidth': termsRect ? `${termsRect.width}px` : '0px',
        }));
      }
    });
  }

  function _handleOnTransitionEnd(): void {
    if (isExpanded) {
      setExpandedCardMeasurements();
    }

    if (!isExpanded) {
      updateInitialCardY();
    }
    onTransitionEnd();
  }

  function _handleOnTransitionStart(): void {
    if (isExpanded) {
      updateInitialCardY();
    }

    onTransitionStart();
  }

  const selectionCardClass = cx('selectionCard', {
    'selectionCard--isShowingDetails': isShowingDetails,
    'selectionCard--isSelected': !isSkipped,
    'selectionCard--isSubmitted': isSubmitted,
    'selectionCard--isAccepted': isAccepted,
    'selectionCard--isExpanded': isExpanded,
  });

  useEffect(() => {
    if (!isListTransitioning) {
      setInitialCardMeasurements();
    }
  }, [width, isListTransitioning]);

  useEffect(() => {
    if (index === 0) {
      setTimeout(() => handleSelectSchool(id), formSpeed + 500);
    }
  }, [index, formSpeed]);

  return (
    <CSSTransition
      key={id}
      timeout={formSpeed}
      delay={100}
      in={isExpanded || isShowingDetails}
      onEntering={_handleOnTransitionStart}
      onEntered={_handleOnTransitionEnd}
      onExiting={_handleOnTransitionStart}
      onExited={_handleOnTransitionEnd}
      classNames="selectionCard"
      nodeRef={selectionCardRef}
    >
      <div
        className={selectionCardClass}
        ref={selectionCardRef}
        style={cardStyles}
      >
        <div
          className="selectionCard__front"
          onClick={() => {
            if (currentBreakpoint === 'desktop') {
              handleSelectSchool(id);
            }
          }}
        >
          <div className="selectionCard__featuredImage">
            <img src={featuredImage.mobile} alt="" />
          </div>
          <div className="selectionCard__body">
            <div className="selectionCard__meta">
              <div className="selectionCard__logo">
                <SchoolLogo
                  logo={schoolLogo}
                  className="selectionCard__logoImg"
                  alt={`${label} logo`}
                />
              </div>
            </div>
            <div className="selectionCard__content">
              <h4 className="selectionCard__title">
                <Wysiwyg content={selectedProgram.label} isClean />
              </h4>
              <div className="selectionCard__subTitle">
                <Wysiwyg content={label} />
              </div>
            </div>
          </div>
          <div className="selectionCard__actions">
            <SelectionCardActions
              id={id}
              showSelect
              showCheckbox={false}
              showSubmit={false}
              submitText={submitText}
              onError={() => {}}
            />
          </div>
        </div>
        <SelectionCardExpanded
          id={id}
          contentRef={expandedContentRef}
          termsRef={termsRef}
        />
      </div>
    </CSSTransition>
  );
}
