import { TRIAD_PROXY_ROUTE } from 'app-requests/apiConstants';
import { SchoolImpressionGuid } from 'types';
import { getSessionDataForLogging, LogError } from 'utils/logging';
import request from 'utils/request';

interface EventData {
  name: string;
  value: string;
}

enum ImpressionEvent {
  SchoolTCPAView = 'Eduprofile.SchoolCard.ViewsTCPA',
  CheckboxClick = 'Eduprofile.SchoolCard.CheckboxChecked',
  SkipClicked = 'Eduprofile.SchoolCard.SkipClicked',
}

/**
 * Logs a micro portal impression tracking event
 * @param eventName - Type of impression event
 * @param schoolImpressionGuid - Unique identifier for the school impression
 * @param tcpaText - TCPA disclaimer text
 * @param eventValue - Optional additional event data
 */
async function logImpressionEvent(
  eventName: ImpressionEvent,
  schoolImpressionGuid: SchoolImpressionGuid,
  tcpaText: string,
  eventValue?: string
): Promise<void> {
  try {
    const { schoolCode } = getSessionDataForLogging();

    const eventData: EventData[] = [{ name: 'tcpaText', value: tcpaText }];

    // its possible if we are using the fake school. see getGTSAndCTSSchoolObject.
    if (schoolImpressionGuid !== '') {
      eventData.push({
        name: 'schoolImpressionGuid',
        value: schoolImpressionGuid,
      });
    }

    if (eventValue !== undefined) {
      eventData.push({ name: 'eventValue', value: eventValue });
    }

    await request({
      method: 'post',
      url: `${TRIAD_PROXY_ROUTE}/logevent`,
      body: {
        schoolCode,
        eventName,
        eventData,
      },
    });
  } catch (error) {
    LogError('Failed to log micro portal impression event', {
      error: error instanceof Error ? error.message : String(error),
      eventName,
      schoolImpressionGuid,
    });
  }
}

/**
 * Tracks when a school's TCPA disclaimer is viewed
 */
export function schoolTCPAView(
  schoolImpressionGuid: SchoolImpressionGuid,
  tcpaText: string
): void {
  logImpressionEvent(
    ImpressionEvent.SchoolTCPAView,
    schoolImpressionGuid,
    tcpaText
  );
}

/**
 * Tracks when a TCPA checkbox is clicked
 */
export function checkboxClick(
  schoolImpressionGuid: SchoolImpressionGuid,
  tcpaText: string,
  isCheckboxChecked: boolean
): void {
  logImpressionEvent(
    ImpressionEvent.CheckboxClick,
    schoolImpressionGuid,
    tcpaText,
    isCheckboxChecked ? '1' : '0'
  );
}

/**
 * Tracks when the skip button is clicked
 */
export function skipClicked(
  schoolImpressionGuid: SchoolImpressionGuid,
  tcpaText: string
): void {
  logImpressionEvent(
    ImpressionEvent.SkipClicked,
    schoolImpressionGuid,
    tcpaText
  );
}

export function recordSchoolView(
  schoolImpressionGuids: SchoolImpressionGuid[],
  viewLocation: string
): Promise<void> {
  return request({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}/ClickPortal/RecordView`,
    body: {
      viewLocation,
      schoolImpressionGuids,
    },
  }).catch(() => {});
}
